<template>
	<ui-modal :show="show" @update:show="$emit('update:show', $event)">
		<ui-panel>
			<ui-panel-body>
				<div class="teesheet-date-selector">

					<div class="selector-nav">
						<button type="button" class="selector-nav-arrow" aria-label="Previous month" @click.prevent="changeMonth(-1)"><i class="fas fa-chevron-left"/></button>
					</div>

					<div class="selector-calendars">
						<div class="row">
							<div class="col-md-4 mb-4">
								<ui-calendar v-model="date" :show-date="prevDate" @selected="dismiss()"/>
							</div>
							<div class="col-md-4 mb-4">
								<ui-calendar v-model="date" :show-date="viewingDate" @selected="dismiss()"/>
							</div>
							<div class="col-md-4 mb-4">
								<ui-calendar v-model="date" :show-date="nextDate" @selected="dismiss()"/>
							</div>
						</div>
					</div>

					<div class="selector-nav">
						<button type="button" class="selector-nav-arrow" aria-label="Next month" @click.prevent="changeMonth(1)"><i class="fas fa-chevron-right"/></button>
					</div>

				</div>
			</ui-panel-body>
		</ui-panel>
	</ui-modal>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations } from 'vuex';

const FORMAT = 'YYYY-MM-DD';

export default {
	props: {
		show: { type: Boolean, default: false },	
	},

	data()
	{
		return {
			//date: moment().format(FORMAT),
			viewingDate: moment().format(FORMAT),
		}
	},

	computed: {
		...mapState({
			currentDate: state => state.teesheet.date,
			course: state => state.teesheet.course,
		}),

		prevDate()
		{
			var current = moment(this.viewingDate, FORMAT).startOf('month');
			return current.subtract(1, 'months').format(FORMAT);
		},

		nextDate()
		{
			var current = moment(this.viewingDate, FORMAT).startOf('month');
			return current.add(1, 'months').format(FORMAT);
		},

		date: 
		{
			get()
			{
				return this.currentDate;
			},

			set(value)
			{
				this.$router.push(`/teesheet/${this.course.url_slug}/${value}`);
				//this.$store.commit('teesheet/setCurrentDate', value);
			}
		}
	},

	methods: {
		changeMonth(dir)
		{
			var baseDate = moment(this.viewingDate, FORMAT);
			this.viewingDate = baseDate.add(dir, 'months').format(FORMAT);
		},

		dismiss()
		{
			this.$emit('update:show', false);
		}
	}
}
</script>