<template>
	<ui-panel>
		<ui-panel-title><div class="panel-heading">Upcoming Lottery Entries</div></ui-panel-title>

		<ui-panel-body v-if="loading">
			Loading your entries...
		</ui-panel-body>

		<ui-list v-else-if="lotteryEntryList.length > 0">
			<!-- BOOKING LIST -->
			<ui-list-section>
				<ui-list-item-row v-for="booking in lotteryEntryList" :key="booking.id" :to="getEntryUrl(booking)">
					<!-- course icon -->
					<ui-list-item-avatar>
						<ui-avatar v-if="courses[booking.course_id].theme_logo" :src="courses[booking.course_id].theme_logo" :size="48" />
						<ui-avatar v-else src="/images/avatar-placeholder.gif" :size="48" />
					</ui-list-item-avatar>

					<!-- booking info -->
					<ui-list-item-content>
						<ui-list-item-title>{{ courses[booking.course_id].name }}</ui-list-item-title>
						<ui-list-item-subtitle>
							{{ booking.dateof | dateFormat('MMM Do, YYYY') }}<br>
							Desired: {{ booking.desired_time | to12Hour }}, 
							Earliest: {{ booking.earliest_time | to12Hour }}, 
							Latest: {{ booking.latest_time | to12Hour }}
						</ui-list-item-subtitle>
					</ui-list-item-content>

					<!-- cancel action -->
					<ui-list-item-icon>
						<ui-icon color="danger" icon="fas fa-times" title="Cancel entry" @click="cancelEntry(booking)"/>
					</ui-list-item-icon>
				</ui-list-item-row>

			</ui-list-section>
		</ui-list>

		<ui-panel-body v-else>
			No upcoming lottery entries found
		</ui-panel-body>

	</ui-panel>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import filters from 'igolf/shared/filters';
import alerts from 'igolf/shared/alerts';

export default {
	mixins: [filters],

	data()
	{
		return {
			loading: true,
		}
	},

	computed: { 
		...mapGetters('bookings', ['lotteryEntryList']),
		...mapState({
			courses: state => state.shell.coursesById,
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		async refreshEntries()
		{
			this.loading = true;

			try
			{
				var date = moment().format('YYYY-MM-DD');
				await this.$store.dispatch('bookings/fetchLotteryEntries', { date });
			}
			finally
			{
				this.loading = false;
			}
		},

		async cancelEntry(tt)
		{
			if (!await alerts.confirm('Are you sure you want to cancel this entry?', 'Cancel Entry', 'question'))
				return;

			this.loading = true;

			try
			{
				await this.$api.delete(`/lottery_entries/${tt.block_id}/${tt.id}`);
				await this.refreshEntries();
			}
			finally
			{
				this.loading = false;
			}
		},

		getEntryUrl(booking)
		{
			var course = this.courses[booking.course_id];
			//var type = this.currentUser.is_public ? 'public_booking' : 'booking';
			return `/teesheet/${course.url_slug}/${booking.dateof}/lottery_entry/${booking.block_id}/${booking.id}`;
		}
	},

	mounted()
	{
		this.refreshEntries();
	}
}
</script>