<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div v-if="courses.length > 0" class="container">
			<div class="title">Select Course</div>

			<div class="row">
				<div v-for="course in courses" :key="course.id" class="col-md-4 col-sm-6 col-xs-12">
					<ui-panel>
						<ui-panel-header size-type="height" :height="100" :src="course.theme_header">
							<ui-panel-header-icon :size="80" :src="course.theme_logo"></ui-panel-header-icon>
						</ui-panel-header>

						<ui-panel-body>
							<div class="subtitle">{{ course.name }}</div>

							<ui-button block color="primary" :to="`/teesheet/${course.url_slug}/today`">Select</ui-button>
						</ui-panel-body>
					</ui-panel>
				</div>
			</div>
		</div>

		<div v-else class="container">
			There are no courses available or no courses have been configured yet.
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import store from 'igolf/users/store';

export default {

	computed: {
		...mapState({
			courses: state => state.shell.courses,
			currentUser: state => state.shell.currentUser,
		})
	},

	beforeRouteEnter(to, from, next)
	{
		var courses = store.state.shell.courses;

		// redirect to teesheet if there is only one course
		if (courses.length == 1)
			next(`/teesheet/${courses[0].url_slug}/today`);
		// redirect to login screen if there are no courses available
		else if (courses.length == 0)
			document.location = '/login';
		else
			next();
	}
}
</script>