
import Vue from 'vue';

import clickHandler from './mixins/click-handler';

function createListComponent(name)
{
	return {
		render(h) {
			return h('div', { class: name }, this.$slots.default);
		}
	};
}

export const List = Vue.component('list', {
	props: {
		maxHeight: { type: Number, default: 0 }
	},

	render(h) {
		var cls = 'list';
		var style = {};

		if (this.maxHeight > 0)
			style = { maxHeight: `${this.maxHeight}px`, overflow: 'auto' };

		return h('div', { class: cls, style }, this.$slots.default);
	}
});

// list item row
export const ListItemRow = Vue.component('list-item-row', { 
	mixins: [clickHandler],

	render(h)
	{
		var tag = 'div';
		var data = {
			class: `list-item-row ${this.$props.class || ''}`,
		};

		if (this.isClickable())
		{
			tag = 'a';

			data.class += ' is-button';
			data.attrs = { role: 'button', href: '#' };

			data.on = {
				click: event => this.handleClick(event),
			};
		}

		return h(tag, data, this.$slots.default);
	}
});

// list item icon 
export const ListItemIcon = Vue.component('list-item-icon', {
	props: {
		color: { type: String, default: null },
	},

	render(h)
	{
		var data = {
			class: 'list-item-icon'
		};

		if (this.color != null)
			data.style = { color: this.color };

		return h('div', data, this.$slots.default);
	}
});

// list item icon 
export const ListItemAvatar = Vue.component('list-item-avatar', {
	render(h)
	{
		var data = {
			class: 'list-item-avatar'
		};

		return h('div', data, this.$slots.default);
	}
});

// create basic List components that are simple <div class="..">...</div> elements
//export const List = createListComponent('list');
export const ListHeader = createListComponent('list-header');
export const ListSection = createListComponent('list-section');
//export const ListItemRow = createListComponent('list-item-row');
export const ListItemContent = createListComponent('list-item-content');
export const ListItemTitle = createListComponent('list-item-title');
export const ListItemSubtitle = createListComponent('list-item-subtitle');
