<template>
	<ui-modal :show="blockId != 0" @update:show="dismiss(!$event)">
		<ui-panel>
			<ui-panel-title><div class="subtitle">Lottery Entries</div></ui-panel-title>
			
			<ui-panel-body v-if="lotteryEntryList.length > 0">
				<ui-form ref="form">
					<div class="row">
						<div class="col-md-4">
							<ui-select-field
								label="Sort By"
								v-model="sortBy"
								:items="orderOptions"
							/>
						</div>
						<div class="col-md-4">
							<ui-select-field
								label="Sort Order"
								v-model="sortDir"
								:items="dirOptions"
							/>
						</div>
						<div class="col-md-4">
							<ui-text-box-field
								label="Filter by player name"
								v-model="search"
							/>
						</div>
					</div>
				</ui-form>
			</ui-panel-body>

			<ui-panel-body v-if="loading">Loading...</ui-panel-body>

			<template v-else-if="filteredEntries.length > 0" >

				<div class="table">
					<table>
						<thead>
							<tr>
								<th>Group</th>
								<th>Earliest Time</th>
								<th>Latest Time</th>
								<th>Desired Time</th>
							</tr>
						</thead>
						<tbody class="is-striped">
							<tr v-for="e in filteredEntries" :key="e.id">
								<td>{{ e.players | playerList }}</td>
								<td>{{ e.earliest_time | to12Hour }}</td>
								<td>{{ e.latest_time | to12Hour }}</td>
								<td>{{ e.desired_time | to12Hour }}</td>
							</tr>
						</tbody>
					</table>
				</div>

			</template>

			<ui-panel-body v-else>
				No entries found
			</ui-panel-body>
			
			<ui-panel-footer>
				<ui-button color="front-primary" @click="dismiss(true)">Close</ui-button>
			</ui-panel-footer>

		</ui-panel>
	</ui-modal>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import filters from 'igolf/shared/filters';

export default {
	mixins: [filters],

	filters: {
		playerList(players)
		{
			var names = players.map(p => `${p.first_name} ${p.last_name}`);
			return names.join(', ');
		},
	},

	data()
	{
		return {
			loading: false,
			sortBy: 'desired_time',
			sortDir: 'asc',
			search: '',

			orderOptions: [
				{ value: 'desired_time', text: 'Desired Time' },
				{ value: 'earliest_time', text: 'Earliest Time' },
				{ value: 'latest_time', text: 'Latest Time' },
			],

			dirOptions: [
				{ value: 'asc', text: 'Earliest to latest' },
				{ value: 'desc', text: 'Latest to earliest' },
			]
		}
	},

	computed: {
		...mapGetters('teesheet', ['lotteryEntryList']),
		...mapState({
			blockId: state => state.teesheet.viewingLotteryEntries,
		}),

		filteredEntries()
		{
			var search = this.search.trim().toLowerCase();
			var entries;

			// filter entries by name search
			if (search != '')
			{
				entries = this.lotteryEntryList.filter(e => 
					e.players.find(p => p.first_name.toLowerCase().indexOf(search) >= 0 || p.last_name.toLowerCase().indexOf(search) >= 0)
				);
			}
			else
			{
				entries = [...this.lotteryEntryList];
			}

			// sort results by time
			entries.sort((a, b) => {
				a = a[this.sortBy];
				b = b[this.sortBy];

				if (a < b)
					return this.sortDir == 'asc' ? -1 : 1;
				else if (a > b)
					return this.sortDir == 'asc' ? 1 : -1;
				else
					return 0;
			});

			return entries;
		}
	},

	watch: {
		blockId()
		{
			if (this.blockId != 0)
				this.loadEntries();
		}
	},

	methods: {
		filterPlayer(p)
		{

		},

		dismiss(flag)
		{
			if (flag)
				this.$store.commit('teesheet/showLotteryEntries', 0);
		},

		async loadEntries()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('teesheet/fetchLotteryEntries', this.blockId);
			}
			finally
			{
				this.loading = false;
			}
		}
	}
}
</script>