
import api from 'igolf/users/api';

export default {
	async fetchGuestList({ commit })
	{
		var result = await api.get('/guests');
		
		commit('setGuestList', result.data.guests);

		return result.data;
	},
}