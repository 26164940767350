<template>
	<div>
		<div class="calendar-navigation">
			<button class="btn-text is-icon" type="button" @click.stop="updateYear(-1)"><i class="fas fa-chevron-left"/></button>
			<button class="calendar-title" type="button" @click.stop="$setParentView('years')">{{ date.year }}</button>
			<button class="btn-text is-icon" type="button" @click.stop="updateYear(1)"><i class="fas fa-chevron-right"/></button>
		</div>

		<ul class="calendar-month-selector">
			<li v-for="m in months" :key="m.value">
				<button 
					class="calendar-menu-item" 
					:class="{ 'is-selected': m.value == date.month }"
					@click.stop="selectMonth(m.value)">
					{{ m.text }}
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	model: { prop: 'date', event: 'input' },
	inject: ['$setParentView'],

	props: {
		date: { type: Object, required: true },
	},

	data()
	{
		return {
			months: [
				{ value: 0, text: 'Jan' },
				{ value: 1, text: 'Feb' },
				{ value: 2, text: 'Mar' },
				{ value: 3, text: 'Apr' },
				{ value: 4, text: 'May' },
				{ value: 5, text: 'Jun' },
				{ value: 6, text: 'Jul' },
				{ value: 7, text: 'Aug' },
				{ value: 8, text: 'Sep' },
				{ value: 9, text: 'Oct' },
				{ value: 10, text: 'Nov' },
				{ value: 11, text: 'Dec' },
			]
		}
	},

	methods: {
		updateYear(dir)
		{
			this.$emit('input', { ...this.date, year: this.date.year + dir });
		},

		selectMonth(month)
		{
			this.$emit('input', { ...this.date, month });
			this.$setParentView('main');
		}
	}
}
</script>