
export default {
	setGuestList(state, list)
	{
		var guests = {};
		
		state.guestList = list.map(b => {
			guests[b.id] = { ...b };
			return b.id;
		});
		state.guests = guests;
	}
}