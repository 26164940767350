
import api from 'igolf/users/api';

export default {
	async fetchBookingList({ commit }, params)
	{
		var result = await api.get('/bookings', { params });
		commit('setBookingList', result.data.teetimes);
	},

	async fetchLotteryEntries({ commit }, params)
	{
		var result = await api.get('/lottery_entries/all', { params });
		commit('setLotteryEntryList', result.data.entries);
	},
}