<template>
	<div class="progress-bar">
		<template v-if="indeterminate">
			<div class="indeterminate-fill-small"/>
			<div class="indeterminate-fill-large"/>
		</template>
		<div v-else class="progress-fill" :style="{ width: `${percent}%` }"/>
	</div>
</template>

<script>
export default {
	props: {
		indeterminate: { type: Boolean, default: false },
		percent: { type: Number, default: 0 },
	},
}
</script>