
export default {
	setBuddyList(state, list)
	{
		var buddies = {};
		
		state.buddyList = list.map(b => {
			buddies[b.id] = { ...b };
			return b.id;
		});
		state.buddies = buddies;
	}
}