<template>
	<div class="main-menu" :class="{ 'is-visible': menuVisible }">
		<div class="main-menu-wrapper">
			<!-- MAIN MENU ITEMS -->
			<ul class="main-menu-items">
				<li><button type="button" class="close-menu-btn" aria-label="Hide menu" @click.prevent="showMenu(false)"><i class="fas fa-times"></i></button></li>
				<li><router-link to="/" class="menu-item">Home</router-link></li>
				<li><router-link to="/courses" class="menu-item is-current">Teesheet</router-link></li>
				
				<template v-if="currentUser != null">
					<li><router-link to="/bookings" class="menu-item">My Teetimes</router-link></li>
					<li><router-link to="/profile" class="menu-item">My Profile</router-link></li>
					<li v-if="!currentUser.is_public"><router-link to="/buddies" class="menu-item">Buddy List</router-link></li>
					<li v-if="!currentUser.is_public"><router-link to="/guests" class="menu-item">Guest List</router-link></li>
					<li v-if="!currentUser.is_public"><router-link to="/messages" class="menu-item">Messages</router-link></li>
					<li><a href="/logout" class="menu-item">Log Out</a></li>
				</template>

				<template v-else>
					<li><a href="/login" class="menu-item">User Login</a></li>
				</template>
			</ul>

			<!-- DISPLAY REMAINING ROUNDS WHEN VIEWING TEESHEET OR CHILD ROUTE -->
			<template v-if="$route.matched && $route.matched[0] && $route.matched[0].name == 'users-teesheet'">
				<remaining-rounds />
			</template>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import RemainingRounds from './RemainingRounds.vue';

export default {
	components: { RemainingRounds },

	computed: {
		...mapState({
			menuVisible: state => state.shell.menuVisible,
			currentUser: state => state.shell.currentUser,
		}),
	},

	watch: {
		'$route': function(){
			this.showMenu(false);
		}
	},

	methods: {
		...mapMutations('shell', ['showMenu']),
	},

	
}
</script>