<template>
	<ui-panel>
		<ui-panel-title><div class="panel-heading">Upcoming Teetimes</div></ui-panel-title>

		<ui-panel-body v-if="loading">
			Loading your teetimes...
		</ui-panel-body>

		<ui-list v-else-if="bookingList.length > 0">
			<!-- BOOKING LIST -->
			<ui-list-section>
				<ui-list-item-row v-for="booking in bookingList" :key="booking.id" :to="getBookingUrl(booking)">
					<!-- course icon -->
					<ui-list-item-avatar>
						<ui-avatar v-if="courses[booking.course_id].theme_logo" :src="courses[booking.course_id].theme_logo" :size="48" />
						<ui-avatar v-else src="/images/avatar-placeholder.gif" :size="48" />
					</ui-list-item-avatar>

					<!-- booking info -->
					<ui-list-item-content>
						<ui-list-item-title>{{ courses[booking.course_id].name }}</ui-list-item-title>
						<ui-list-item-subtitle>
							{{ booking.dateof | dateFormat('MMM Do, YYYY') }}<br>
							{{ booking.timeof | to12Hour }}<br>
							Players: {{ booking.players.join(', ') }}
						</ui-list-item-subtitle>
					</ui-list-item-content>

					<!-- cancel action -->
					<ui-list-item-icon>
						<ui-icon color="danger" icon="fas fa-times" title="Cancel teetime" @click="cancelTeetime(booking)"/>
					</ui-list-item-icon>
				</ui-list-item-row>

			</ui-list-section>
		</ui-list>

		<ui-panel-body v-else>
			No upcoming teetimes found
		</ui-panel-body>

	</ui-panel>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import filters from 'igolf/shared/filters';
import alerts from 'igolf/shared/alerts';

export default {
	mixins: [filters],

	data()
	{
		return {
			loading: true,
		}
	},

	computed: { 
		...mapGetters('bookings', ['bookingList']),
		...mapState({
			courses: state => state.shell.coursesById,
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		async refreshBookings()
		{
			this.loading = true;

			try
			{
				var date = moment().format('YYYY-MM-DD');
				var time = moment().format('HH:mm');
				await this.$store.dispatch('bookings/fetchBookingList', { date, time });
			}
			finally
			{
				this.loading = false;
			}
		},

		async cancelTeetime(tt)
		{
			if (!await alerts.confirm('Are you sure you want to cancel this booking?', 'Cancel Teetime', 'question'))
				return;

			this.loading = true;

			try
			{
				var params = { id: tt.id }
				await this.$api.post('/reservations/remove_player', params);
				await this.refreshBookings();
			}
			finally
			{
				this.loading = false;
			}
		},

		getBookingUrl(booking)
		{
			var course = this.courses[booking.course_id];
			var type = this.currentUser.is_public ? 'public_booking' : 'booking';
			return `/teesheet/${course.url_slug}/${booking.dateof}/${type}/${booking.timeof}_${booking.hole}`;
		}
	},

	mounted()
	{
		this.refreshBookings();
	}
}
</script>