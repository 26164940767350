<template>
	<form class="ui-form" v-on="$listeners">
		<slot></slot>
	</form>
</template>

<script>
export default {
	provide() {
		return {
			'$addToValidation': this.addControl,
			'$removeFromValidation': this.removeControl,
		}	
	},

	data()
	{
		return {
			valid: true,
			controls: [],
		}
	},

	methods: {
		addControl(ctrl)
		{
			this.controls.push(ctrl);
		},

		removeControl(ctrl)
		{
			var pos = this.controls.indexOf(ctrl);
			if (pos >= 0)
				this.controls.splice(pos, 1);
		},

		isValid()
		{
			return this.valid;
		},

		validate()
		{
			// reset valid flag to true
			this.valid = true;

			// go through each control, if it defines a function called validateField execute it
			// if the function returns false then the entire validation is invalid
			for(var ch of this.controls)
			{
				if (ch.validateField && typeof(ch.validateField) == 'function')
				{
					if (!ch.validateField.call(this))
					{
						this.valid = false;
					}
				}
			}

			// return current value of valid flag
			return this.valid;
		}
	}
}
</script>