<template>
	<div class="users-menubar">
		<div class="menubar-content">
			<div class="menubar-icons">
				<a v-if="course && course.homepage_link" :href="course.homepage_link" class="menubar-icon" title="Return to Main Site"><i class="fas fa-arrow-left"/></a>
				<router-link to="/" class="menubar-icon" title="Return to teesheet"><i class="fas fa-home" /></router-link>
			</div>

			<!-- USER INFO -->
			<div v-if="currentUser != null" class="user-info">
				<div class="user-avatar">
					<img v-if="currentUser.photo_url" :src="currentUser.photo_url">
					<div v-else class="user-initials">{{ userInitials }}</div>
				</div>
				<div class="user-details">
					<div class="avatar-label">Welcome</div>
					<div class="user-name">{{ currentUser.first_name }} {{ currentUser.last_name }}</div>
				</div>
			</div>

			<span class="menubar-spacer" />

			<!-- TOGGLE MENU BTN -->
			<button type="button" class="toggle-menu-btn" aria-label="Menu" @click.prevent="showMenu(true)">
				<span class="bar"></span>
				<span class="bar"></span>
				<span class="bar"></span>
			</button>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
	computed: {
		...mapState({
			currentUser: state => state.shell.currentUser,
			course: state => state.teesheet.course,
		}),

		userInitials()
		{
			if (!this.currentUser)
				return '';
				
			var str = '';

			str += this.currentUser.first_name[0];
			str += this.currentUser.last_name[0];

			return str.toUpperCase();
		}
	},

	methods: {
		...mapMutations('shell', ['showMenu']),
	}	
}
</script>