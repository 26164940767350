<template>
	<ui-panel>
		<!-- SEARCH BOX -->
		<ui-panel-body>
			<div v-if="label" class="caption">{{ label }}</div>

			<ui-text-box-field 
				hide-label
				class="mb-0"
				placeholder="Start typing here to search"
				:value="search"
				@input="updateSearch($event)" 
			/>
		</ui-panel-body>

		<ui-panel-body v-if="searching">
			Searching...
		</ui-panel-body>

		<template v-else>
			<ui-panel-body v-if="search.trim() == ''">
				No search text entered
			</ui-panel-body>

			<ui-panel-body v-else-if="results.length == 0">
				No results found
			</ui-panel-body>

			<!-- USER LIST -->
			<ui-list v-else>
				<ui-list-section>
					<ui-list-item-row v-for="user in results" :key="user.id" @click="$emit('selected', user)">
						<ui-list-item-avatar v-if="user.user_type == 'member'">
							<ui-avatar src="/images/avatar-placeholder.gif" :size="36" />
						</ui-list-item-avatar>

						<ui-list-item-content>
							<ui-list-item-title>{{ user.first_name }} {{ user.last_name }}</ui-list-item-title>
						</ui-list-item-content>
					</ui-list-item-row>
				</ui-list-section>
			</ui-list>
		</template>
		
	</ui-panel>
</template>

<script>
export default {
	props: {
		label: { type: String },
		userType: { type: String, default: 'member' },
	},

	data()
	{
		return {
			searching: false,
			results: [],
			search: '',
		}
	},

	methods: {
		async updateSearch(text)
		{
			this.search = text;

			if (this.search.trim().length < 3)
				return;

			this.searching = true;

			try
			{
				var params = {
					search: this.search,
					user_types: [ this.userType ],
				};

				var result = await this.$api.get('/user_search', {params});
				this.results = result.data.users;
			}
			finally
			{
				this.searching = false;
			}
		}
	}
}
</script>