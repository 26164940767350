<template>
	<div class="member-search-widget">
		<!-- FILTER RESULTS BY SELECTED TYPE -->
		<div v-if="!forceType" class="member-search-filter">
			<div class="filter-label">Filter By:</div>
			<div class="radio-list horz">
				<ui-radio v-model="typeFilter" value="member" label="Members"></ui-radio>
				<ui-radio v-model="typeFilter" value="guest" label="Guests"></ui-radio>
				<ui-radio v-model="typeFilter" value="both" label="Members &amp; Guests"></ui-radio>
			</div>
		</div>

		<!-- SEARCH BAR -->
		<div class="member-search-bar" :class="{ 'is-active': isActive }">
			<!-- ICON/LOADING INDICATOR -->
			<div class="search-bar-icon">
				<i :class="icon"/>
			</div>

			<!-- ACTUAL SEARCH FIELD -->
			<input 
				ref="searchField"
				type="text" 
				class="search-bar-field" 
				:placeholder="`Start typing to search members...`" 
				:value="search"
				@input="searchUsers($event.target.value)"
				@keyup="onFieldKeyPress($event)"
				@focus="setActive(true)" 
				@blur="setActive(false)">

			<!-- SEARCH RESULT LIST -->
			<div class="search-bar-dropdown">

				<div v-if="loading" class="search-bar-message">Searching...</div>

				<!-- user has searched but was found wanting -->
				<div v-else-if="useList.length == 0" class="search-bar-message">No members found</div>

				<!-- results were found! -->
				<ui-list v-else>
					<ui-list-item-row v-for="(m, index) in useList" :key="m.id" :class="{'is-selected': selectedId == m.id}" @click="selectMember(index)">
						<ui-list-item-avatar>
							<ui-avatar :src="`${m.photo_url || '/images/avatar-placeholder.gif'}`" :size="48"/>
						</ui-list-item-avatar>

						<!-- display member info -->
						<ui-list-item-content v-if="m.user_type == 'member'">
							<ui-list-item-title>{{ m.last_name }}, {{ m.first_name }}</ui-list-item-title>
							<ui-list-item-subtitle>{{ m.class_name }} {{ m.member_number }}</ui-list-item-subtitle>
						</ui-list-item-content>

						<!-- display guest info -->
						<ui-list-item-content v-else-if="m.user_type == 'guest'">
							<ui-list-item-title>{{ m.last_name }}, {{ m.first_name }}</ui-list-item-title>
							<ui-list-item-subtitle>{{ m.class_name }}</ui-list-item-subtitle>
						</ui-list-item-content>

						<!-- display fill info -->
						<ui-list-item-content v-else-if="m.user_type == 'fill'">
							<ui-list-item-title>{{ m.fill_desc }}</ui-list-item-title>
						</ui-list-item-content>

					</ui-list-item-row>
				</ui-list>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		forceType: { type: String },
		courseId: { type: Number, required: true },
	},

	data()
	{
		return {
			search: '',
			selected: -1,
			isActive: false,
			loading: false,
			typeFilter: 'member',

			members: []
		}
	},

	computed: { 
		...mapGetters('buddies', ['buddyList']),
		...mapGetters('guests', ['guestList']),

		icon()
		{
			if (this.loading)
				return 'fas fa-circle-notch fa-spin';
			else
				return 'fas fa-search';
		},

		selectedId()
		{
			if (this.selected >= 0 && this.selected < this.useList.length)
				return this.useList[this.selected].id;
			else
				return 0;
		},

		guestsAndBuddies()
		{
			// initialize with buddies
			var list = [ ...this.buddyList ];
			var listIds = list.map(u => u.id);

			// add guests to list if they're not already in the buddy list
			list = list.concat(this.guestList.filter(g => !listIds.includes(g.id)));

			// sort by first name
			list.sort((a, b) => {
				a = a.first_name.toLowerCase();
				b = b.last_name.toLowerCase();

				if (a < b)
					return -1;
				else if (a > b)
					return 1;
				else
					return 0;
			});

			return list;
		},

		useList()
		{
			if (this.search.trim() == '')
				return this.guestsAndBuddies;
			else
				return this.members;
		}
	},

	watch: {
		typeFilter(newVal)
		{
			this.refreshResults();
		}
	},

	methods: {
		onFieldKeyPress(event)
		{
			// if the user pressed the up key decrement selected
			if (event.which == 38 && this.selected > 0)
				this.selected--;
			// if the user pressed the down key increment selected
			else if (event.which == 40 && this.selected + 1 < this.useList.length)
				this.selected++;
			// if the user pressed the enter key then select the current user
			else if (event.which == 13 && this.selected != -1)
				this.selectMember(this.selected);
		},

		setActive(flag)
		{
			// if not previously active then clear the member's results array
			if (!this.isActive && flag)
				this.members = [];

			this.isActive = flag;
		},

		async refreshResults()
		{
			// otherwise fetch results from server
			this.loading = true;
			this.selected = -1;

			try
			{
				var results, params;
				
				// setup base parameters
				params = {
					search: this.search,
					course_id: this.courseId,
				};

				// add user type filter
				if (this.typeFilter != 'both')
					params.user_types = [this.typeFilter];
				else
					params.user_types = ['member', 'guest'];
				
				// fetch results from server
				results = await this.$api.get('/user_search', { params });
				this.members = results.data.users || [];
			}
			finally
			{
				this.loading = false;
			}
		},

		searchUsers(val)
		{
			// clear highlighted user
			this.selected = -1;

			// store search text
			this.search = val;

			// if search text is >= 3 characters long then refresh results
			if (this.search.length >= 3)
				this.refreshResults();
			else if (this.search == '')
				this.members = [];
		},


		selectMember(index)
		{
			// send selected member info to owner
			if (index >= 0 && index < this.useList.length)
				this.$emit('selected', this.useList[index]);

			// clear search field and results
			this.$refs.searchField.blur();
			this.search = '';
			this.members = [];
		}
	},

	created()
	{
		if (this.forceType)
			this.typeFilter = this.forceType;
	}
}
</script>