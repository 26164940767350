<template>
	<ui-modal :show="show" @update:show="updateShow($event)">
		<ui-panel>
			<ui-panel-title><div class="panel-heading">Create Guest</div></ui-panel-title>
			<ui-panel-body>
				<ui-form ref="form">
					<div class="row">
						<div class="col-md-6">
							<ui-text-box-field
								label="First Name"
								:rules="rules.first_name"
								v-model="guest.first_name">
							</ui-text-box-field>
						</div>
						<div class="col-md-6">
							<ui-text-box-field
								label="Last Name"
								:rules="rules.last_name"
								v-model="guest.last_name">
							</ui-text-box-field>
						</div>
						<div class="col-md-6">
							<ui-text-box-field
								label="Email"
								:rules="rules.email"
								v-model="guest.primary_email">
							</ui-text-box-field>
						</div>
						<div class="col-md-6">
							<ui-select-field
								label="Gender"
								:items="genderOptions"
								:rules="rules.gender"
								v-model="guest.gender">
							</ui-select-field>
						</div>
					</div>
				</ui-form>
			</ui-panel-body>
			<ui-panel-footer>
				<ui-button color="primary" :loading="saving" @click="save">Save</ui-button>
				<ui-button color="text" :disabled="saving" @click="dismiss(false)">Close</ui-button>
			</ui-panel-footer>
		</ui-panel>
	</ui-modal>
</template>

<script>
import validate from 'igolf/shared/validators';

const defaultGuest = { first_name: '', last_name: '', primary_email: '', gender: '' };

import alerts from 'igolf/shared/alerts';

export default {
	props: {
		show: { type: Boolean, default: false },
	},

	data()
	{
		return {
			saving: false,
			guest: { ...defaultGuest },

			genderOptions: [
				{ text: '(select gender)', value: '' },
				{ text: 'Male', value: 'male' },
				{ text: 'Female', value: 'female' },
				//{ text: 'Other', value: 'other' },
			],

			rules: {
				first_name: [ v => !!v || 'Please enter the guest\'s first name' ],
				last_name: [ v => !!v || 'Please enter the guest\'s last name' ],
				last_name: [ v => !!v || 'Please select the guest\'s gender' ],
				email: [ 
					v => !!v || 'Please enter the guest\'s email address',
					v => validate.email(v) || 'Invalid email address',
				],
			}
		}
	},

	methods: {
		// tell the container to hide the popup
		dismiss(refresh)
		{
			this.guest = { ...defaultGuest };
			this.$emit('close', refresh);
		},

		// if the user clicked the built-in closed button or triggered an out-side click
		updateShow(flag)
		{
			// if flag is false then dismiss the popup
			if (!flag)
				this.dismiss(false);
		},

		async checkExisting()
		{
			var result = await this.$api.post('/guests/check_existing', { guest: this.guest });
			return result.data.count > 0;
		},

		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				// make sure user doesn't create a duplicate guest
				if (!await this.checkExisting())
				{
					// send guest info to server
					await this.$api.post('/guests', { guest: this.guest });

					// dismiss popup
					this.dismiss(true);
				}
				else
				{
					await alerts.show('A similar guest record already exists', 'Error', 'warning');
				}
			}
			finally
			{
				this.saving = false;
			}
		}
	}
}
</script>