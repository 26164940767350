<template>
	<div :class="`notice notice-${type}`">
		<div v-if="icon" class="notice-icon">
			<i :class="icon"></i>
		</div>
		<div class="notice-content">
			{{ msg }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		icon: { type: String, default: null },
		type: { type: String, required: true },
		msg: { type: String, required: true },
	},
}
</script>