<template>
	<ui-form-field 
		ref="formField"
		v-bind="formFieldAttrs"
		:focused="hasFocus"
		:validate-value="value"
		>

		<div class="time-field">
			<input type="text" class="field-control" size="3" v-model="hours" @blur="updateTime">
			
			<span class="time-field-colon">&nbsp;:&nbsp;</span>

			<input type="text" class="field-control" size="3" v-model="mins" @blur="updateTime">
			
			<select v-if="show12hour" v-model="period" class="field-control">
				<option value="am">AM</option>
				<option value="pm">PM</option>
			</select>
		</div>
	</ui-form-field>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	inject: ['$addToValidation'],

	props: {
		show12hour: { type: Boolean, default: true },
		value: { type: String, required: true },
	},

	data()
	{
		return { 
			hours: '',
			mins: '',
			period: 'am',
		}
	},

	watch: {
		value(newVal)
		{
			this.convertValue(newVal);
		},

		period(p)
		{
			this.updateTime();
		}
	},

	methods: {
		convertValue(newVal)
		{
			var date = moment().format('YYYY-MM-DD');
			var formatted = moment(`${date} ${newVal}`, 'YYYY-MM-DD HH:mm');

			if (this.show12hour)
				this.hours = formatted.format('h');
			else
				this.hours = formatted.format('H');

			this.mins = formatted.format('mm');
			this.period = formatted.format('a');
		},

		updateTime()
		{
			var date = moment().format('YYYY-MM-DD');
			var hours = parseInt(this.hours, 10);
			var mins = parseInt(this.mins, 10);
			var formatted;

			mins = _.clamp(mins, 0, 59);

			if (this.show12hour)
			{
				hours = _.clamp(hours, 1, 12);
				formatted = moment(`${date} ${hours}:${_.padStart(mins, 2, '0')}${this.period}`, 'YYYY-MM-DD h:mma');
			}
			else
			{
				hours = _.clamp(hours, 0, 23);
				formatted = moment(`${date} ${hours}:${_.padStart(mins, 2, '0')}`, 'YYYY-MM-DD H:mm');
			}

			this.$emit('input', formatted.format('HH:mm'));
		}
	},

	mounted()
	{
		this.convertValue(this.value);
	}

}
</script>

