
import router from 'igolf/users/router';
import store from 'igolf/users/store';

import TeesheetMain from './comp/TeesheetMain.vue';
import Teetimes from './comp/Teetimes.vue';
import BookingForm from './comp/BookingForm.vue';
import PublicBookingForm from './comp/PublicBookingForm.vue';
import LotteryEntryForm from './comp/LotteryEntryForm.vue';
import ViewUser from './comp/ViewUser.vue';

// require a user to be logged in and for that user to be a private member
const requireMember = (to, from, next) => {
	let user = store.state.shell.currentUser;

	if (user == null)
	{
		document.location = '/login';
	}
	else if (user.is_public)
	{
		alert('Permission denied');
		next(false);
	}
	else
	{
		next();
	}
}

router.addRoutes([
	{
		name: 'users-teesheet',
		path: '/teesheet/:slug/:date',
		props: true,
		component: TeesheetMain,
		children: [
			{
				name: 'users-teesheet-teetimes',
				path: '',
				component: Teetimes,
			},
			{
				name: 'users-teesheet-booking-form',
				path: 'booking/:id',
				props: true,
				meta: { hideHeader: true },
				component: BookingForm,
				beforeEnter: requireMember,
			},
			{
				name: 'users-teesheet-public-booking-form',
				path: 'public_booking/:id',
				props: true,
				meta: { hideHeader: true },
				component: PublicBookingForm,
				beforeEnter: (to, from, next) => {
					let user = store.state.shell.currentUser;

					// if the user is logged in as a member then redirect them to the member's booking form instead!
					if (user == null || !user.is_public)
						next(`/teesheet/${to.params.slug}/${to.params.date}/booking/${to.params.id}`);
					else
						next();
				},
			},
			{
				name: 'users-teesheet-lottery-entry-form',
				path: 'lottery_entry/:blockId/:id',
				props: true,
				meta: { hideHeader: true },
				component: LotteryEntryForm,
				beforeEnter: requireMember,
			},
			{
				name: 'users-teesheet-user-info',
				path: 'user/:id',
				props: true,
				meta: { hideHeader: true },
				component: ViewUser,
				beforeEnter: requireMember,
			}
		]
	}
]);
