<template>
	<div :class="`course-${course.id}`">
		<div v-if="$route.meta.hideHeader" class="shell-header-top no-banner"></div>
		<teesheet-header v-else banner-size="large" />

		<template v-if="!loaded">
			Loading...
		</template>

		<template v-else>
			<router-view/>
		</template>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

import store from 'igolf/users/store';
import alerts from 'igolf/shared/alerts';

import TeesheetHeader from './TeesheetHeader.vue';

async function initScreen(to)
{
	const { shell, teesheet } = store.state;
	var slug = to.params.slug;
	var date = to.params.date == 'today' ? moment() : moment(to.params.date, 'YYYY-MM-DD');

	// make sure course exists
	if (!slug || !shell.coursesBySlug[slug])
	{
		await alerts.show(`Invalid course path: ${slug}`);
		return false;
	}

	// make sure date is valid
	if (!to.params.date || !date.isValid())
	{
		await alerts.show(`Invalid date: ${to.params.date}`);
		return false;
	}

	// init state
	store.commit('teesheet/setCurrentCourse', shell.coursesBySlug[slug]);
	store.commit('teesheet/setCurrentDate', date.format('YYYY-MM-DD'));

	return true;
}

export default {
	components: { TeesheetHeader },
	inject: ['$setLoadingCover'],

	props: {
		slug: { type: String },
		date: { type: String },
	},

	data()
	{
		return {
			loading: true,
		}
	},

	computed: {
		...mapState({
			coursesBySlug: state => state.shell.coursesBySlug,
			loaded: state => state.teesheet.loaded,
			course: state => state.teesheet.course,
			viewingLotteryEntries: state => state.teesheet.viewingLotteryEntries,
		})
	},

	methods: {
		async initDetails()
		{
			this.$setLoadingCover(true);
			this.loading = true;

			try
			{
				await this.$store.dispatch('teesheet/fetchTeesheetDetails');
			}
			finally
			{
				this.$setLoadingCover(false);
				this.loading = false;
			}
		}
	},

	async beforeRouteEnter(to, from, next)
	{
		if (await initScreen(to))
			next(vm => vm.initDetails());
		else
			next(false);
	},

	async beforeRouteUpdate(to, from, next)
	{
		if (await initScreen(to))
		{
			next();
			this.initDetails();
		}
		else
		{
			next(false);
		}
	}
}
</script>