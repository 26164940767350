import 'styles/users.scss';

import Vue from 'vue';

// api plugin
import api from 'igolf/users/api';

// import common modules
import 'igolf/ux';
import 'igolf/shared/breakpoints';

// import state storage
import store from 'igolf/users/store';

// import routing
import router from 'igolf/users/router';

// import user shell
import UsersMain from 'igolf/users/shell/comp/UsersMain.vue';

// import screens
import 'igolf/users/courses';
import 'igolf/users/teesheet';
import 'igolf/users/bookings';
import 'igolf/users/buddies';
import 'igolf/users/guests';
import 'igolf/users/profile';
import 'igolf/users/messages';
//import 'igolf/users/pop';

// set account info
store.commit('shell/setAccountInfo', window._initData.account);

// set courses
store.commit('shell/setCourses', window._initData.courses);

// set current user info
store.commit('shell/setCurrentUser', window._initData.currentUser);
store.commit('shell/setUserClass', window._initData.userClass);

// initialize interface notes
store.commit('shell/setInterfaceNotes', _initData.interfaceNotes);

// set cart options
store.commit('shell/setCartList', window._initData.carts);

// configure global API
Vue.prototype.$api = api;

// initialize Vue SPA
const app = new Vue({
	el: '#app-root',
	router,
	store,

	render(h) {
		return h(UsersMain);
	},
})
