<template>
	<div>
		<!-- teetime member players -->
		<div
			v-for="m in teetimePlayers" 
			:key="m.id">

			<user-card :user="m" type="user" />
		</div>

		<!-- teetime fill players -->
		<div
			v-for="m in teetimeFills" 
			:key="m.id">

			<user-card :user="m" type="fill" />
		</div>
	</div>
</template>

<script>
import swal from 'sweetalert2';
import { mapState, mapGetters, mapMutations } from 'vuex';

import alerts from 'igolf/shared/alerts';

import UserCard from  './MemberCard.vue';

export default {
	components: { UserCard },

	props: {
		isLottery: { type: Boolean, default: false },
	},

	computed: {
		...mapGetters('teesheet', ['teetimePlayers', 'teetimeFills']),
		...mapState({
			reservation: state => state.teesheet.reservation,
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		...mapMutations('teesheet', ['removeTeetimePlayer', 'updateReservationDetails']),

		isOwner(id)
		{
			return this.reservation.user_id == id && this.currentUser.id == id;
		},

		/*
		// called when the user attempts to remove the owner of the entry
		async removePlayer(id)
		{
			// is the owner of this entry the current user?
			if (isOwner(id))
			{
				var result = await swal.fire({ 
					text: 'You are the owner of this entry, do you wish to cancel the entry or change the owner?',
					title: 'Remove Player',
					icon: 'question', 
					showCancelButton: true, 
					confirmButtonText: 'Change Owner',
					cancelButtonText: 'Cancel Entry',
					cancelButtonColor: '#fd4c4c',
				});

				// if the user clicked Change Owner then remove the player and set the owner to the first player in the list
				if (result.value)
				{
					// if there is only one player in the list then raise another error
					if (this.teetimePlayers.length == 1)
					{
						alerts.show('You cannot remove the only player in the reservation', 'Error', 'error');
						return;
					}

					this.removeTeetimePlayer(id);

					if (this.teetimePlayers.length > 0)
						this.updateReservationDetails({ user_id: this.teetimePlayers[0].id });
					else
						this.updateReservationDetails({ user_id: 0 });
				}
			}
			// if not then prevent them from removing the user
			else
			{
				alerts.show('You cannot remove the owner from the entry, please change the owner first.', 'Error', 'error');
			}
		},
		*/
	}
}
</script>