
import router from 'igolf/users/router';

import { addRequireUserGuard } from '../shared/require-user';

import BookingsMain from './comp/BookingsMain.vue';

router.addRoutes(addRequireUserGuard([
	{
		name: 'users-bookings-main',
		path: '/bookings',
		component: BookingsMain
	}
]));