
import router from 'igolf/users/router';

import { addRequireUserGuard } from '../shared/require-user';

import ProfileMain from './comp/ProfileMain.vue';
import ContactForm from './comp/ContactForm.vue';

router.addRoutes(addRequireUserGuard([
	{
		name: 'users-profile',
		path: '/profile',
		component: ProfileMain,
	},
	{
		name: 'users-contact-form',
		path: '/contacts/:id',
		props: true,
		component: ContactForm,
	}
]));