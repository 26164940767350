
export default {
	setBookingList(state, list)
	{
		var bookings = {};

		state.bookingList = list.map(b => {
			bookings[b.id] = { ...b };
			return b.id;
		});
		state.bookings = bookings;
	},

	setLotteryEntryList(state, list)
	{
		var entries = {};
		
		state.lotteryEntryIds = list.map(e => {
			entries[e.id] = { ...e };
			return e.id;
		});
		state.lotteryEntries = entries;
	}
}