<template>
	<div>
		<div v-if="isVisible" class="menu-widget">
			<div class="widget-title">Remaining Rounds</div>

			<div class="remaining-rounds">
				<div class="remaining-amount">
					<div class="amount-label">This season</div>
					<div class="amount-value">{{ perSeason || 'Unlimited' }}</div>
				</div>
				<div class="remaining-amount">
					<div class="amount-label">This year</div>
					<div class="amount-value">{{ perYear || 'Unlimited' }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	data()
	{
		return {
			loading: false,
			perSeason: null,
			perYear: null,
		}
	},

	computed: {
		...mapState({
			course: state => state.teesheet.course,
		}),

		isVisible()
		{
			return this.course != null && (this.perSeason != null || this.perYear != null);
		}
	},

	methods: {
		async fetchRemainingRounds()
		{
			this.loading = true;
			this.perSeason = null;
			this.perYear = null;

			if (this.course == null)
				return;

			try
			{
				var params, result;

				params = {
					course_id: this.course.id,
					date: moment().format('YYYY-MM-DD'),
				};

				var result = await this.$api.get('/profile/remaining_rounds', { params });

				if (result.data.remaining && result.data.remaining.total_rounds)
				{
					this.perSeason = result.data.remaining.total_rounds.per_season;
					this.perYear = result.data.remaining.total_rounds.per_year;
				}
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.fetchRemainingRounds();
	}
}
</script>
