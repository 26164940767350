<template>
	<div class="container">
		<ui-panel>
			<ui-menubar>
				<ui-menubar-item primary label="Back To Teesheet" icon-left="fas fa-arrow-circle-left" :to="returnUrl" />
			</ui-menubar>
		</ui-panel>

		<div class="title">{{ user.first_name }} {{ user.last_name}}</div>		
		<ui-panel v-if="Object.keys(user_details).length">
			<ui-panel-body>
				<div class="row">
					<div class="col shrink">
						<div class="pa-4 pr-0">
							<ui-avatar :size="128" :src="user_details.photo_url"/>
						</div>
					</div>
					<div class="col grow">

						<ui-list class="pt-4 pb-4">
							<ui-list-item-row v-for="(field, name) in user_detail_fields" :key="name">
								<ui-list-item-content>
									<ui-list-item-subtitle>{{ field.title }}</ui-list-item-subtitle>
									<ui-list-item-title class="has-dark-text">
										<template v-if="field.type === 'email'">
											<a :href="'mailto:' + user_details[name]">{{ user_details[name] || 'n/a' }}</a>
										</template>
										<template v-else-if="field.type === 'tel'">
											<a :href="'tel:' + user_details[name]">{{ user_details[name] || 'n/a' }}</a>
										</template>
										<template v-else>
											{{ user_details[name] || 'n/a' }}
										</template>
									</ui-list-item-title>
								</ui-list-item-content>
							</ui-list-item-row>
						</ui-list>
					</div>
					
				</div>
			</ui-panel-body>
		</ui-panel>
		<div v-if="loading">Loading...</div>

		<ui-panel v-else-if="contacts.length == 0 && Object.keys(user_details).length == 0">
			<ui-panel-body>No contact information found</ui-panel-body>
		</ui-panel>

		<template v-else>
			<ui-form>
				<ui-panel v-for="con in contacts" :key="con.id">
					<ui-panel-title><div class="panel-caption">{{ con.name }}</div></ui-panel-title>

					<ui-panel-body>
						<div class="row">
							<div v-for="(info, field) in fields" :key="field" :class="info.size">
								<ui-text-box-field
									readonly
									:label="info.title"
									:value="con[field]"
								/>
							</div>
						</div>
					</ui-panel-body>
				</ui-panel>
			</ui-form>
		</template>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import api from 'igolf/users/api';

export default {

	props: {
		id: { required: true },
	},

	data()
	{
		return {
			loading: false,
			contacts: [],
			user: { },
			user_details: {},

			user_detail_fields: {
				full_name: {title: 'Name', type: 'string'},				
				class: {title: 'Member Class', type: 'string'},
				primary_email: {title: 'Email', type: 'email'},
				primary_phone: {title: 'Phone', type: 'tel'}
			},
			fields: {
				email: { size: 'col-xs-12', title: 'Email', },
				phone: { size: 'col-md-6', title: 'Phone', },
				fax: { size: 'col-md-6', title: 'Fax', },
				address: { size: 'col-xs-12', title: 'Address', },
				city: { size: 'col-md-6', title: 'City', },
				region: { size: 'col-md-6', title: 'Province/State', },
				country: { size: 'col-md-6', title: 'Country', },
				postal_code: { size: 'col-md-6', title: 'Postal Code' },
			}
		}
	},

	computed: {
		...mapState({
			course: state => state.teesheet.course,
			courseParams: state => state.teesheet.courseParams,
			date: state => state.teesheet.date,
		}),

		returnUrl()
		{
			return `/teesheet/${this.course.url_slug}/${this.date}`;
		}
	},

	methods: {
		async fetchInfo()
		{
			this.loading = true;

			try
			{
				var params;
				params = {
					course_id: this.course.id					
				};
				var result = await api.get(`/users/${this.id}`, {params});				
				this.user = result.data.user;
				this.contacts = result.data.contacts;
				this.user_details = result.data.user_details;

			}
			catch(err)
			{
				//this.$router.push(returnUrl);
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.fetchInfo();
	}
}
</script>