
import router from 'igolf/users/router';

import { addRequireUserGuard } from '../shared/require-user';

import MessagesMain from './comp/MessagesMain.vue';
import NewMessage from './comp/NewMessage.vue';
import Conversation from './comp/Conversation.vue';

router.addRoutes(addRequireUserGuard([
	{
		name: 'users-messages-home',
		path: '/messages',
		component: MessagesMain,
	},
	{
		name: 'users-start-conversation',
		path: '/conversation/new',
		component: NewMessage,
	},
	{
		name: 'users-message-conversation',
		path: '/conversation/:id',
		props: true,
		component: Conversation,
	},

]));