
export default {
	teetimeRows: state => state.teetimes.times.map(t => state.teetimes.rows[t]),

	rowsInSegment: state => index => state.segments[index].teetimes.map(id => state.teetimes.rows[id]),

	teetimePlayers: state => state.teetimePlayers.ids.map(id => state.teetimePlayers.players[id]),

	teetimePlayerOptions: state => state.teetimePlayers.ids.map(id => {
		var player = state.teetimePlayers.players[id];
		return { text: `${player.last_name}, ${player.first_name}`, value: id };
	}),
	
	teetimeFills: state => state.teetimeFills.ids.map(id => state.teetimeFills.fills[id]),

	teetimeBookers: state => {
		var players = state.teetimePlayers.ids.map(id => state.teetimePlayers.players[id]);
		return players.filter(p => p.user_type == 'member');
	},
	
	lotteryEntryList: state => state.lotteryEntries.ids.map(id => state.lotteryEntries.entries[id]),

	publicGuestList: state => state.publicPlayerGuests.ids.map(id => state.publicPlayerGuests.guests[id]),
}