<template>
	<div class="ui-tooltip-wrapper" :class="{ 'is-inline': inline }">
		<div class="ui-tooltip-active-area">
			<slot name="active"></slot>
		</div>
		
		<div ref="popup" class="ui-tooltip-popup">
			<slot></slot>
		</div>
	</div>
</template>

<script>
const VIEWPORT_PADDING = 40;

export default {
	props: {
		inline: { type: Boolean, default: false },
	},

	methods: {
		// keep tooltip within viewport
		fixTooltipPosition()
		{
			// get bounds of tooltip element
			var bounds = this.$refs.popup.getBoundingClientRect();

			// get viewport dimensions
			var viewWidth = document.documentElement.offsetWidth;
			//var viewHeight = document.documentElement.offsetHeight;

			// amounts to adjust position by when non-null
			var left = null, top = null;//, right = null, bottom = null;
			
			// fix left edge of tooltip
			if (bounds.left < VIEWPORT_PADDING)
				left = VIEWPORT_PADDING;
			// fix right edge of tooltip
			else if (bounds.right >= viewWidth - VIEWPORT_PADDING)
				left = -(bounds.right - (viewWidth - VIEWPORT_PADDING));

			/*
			// fix top edge of tooltip
			if (bounds.top < VIEWPORT_PADDING)
				top = VIEWPORT_PADDING;
			// fix bottom edge of tooltip
			else if (bounds.bottom >= viewHeight - VIEWPORT_PADDING)
				top = -(bounds.bottom - (viewHeight - VIEWPORT_PADDING));
			*/

			// fix positions
			if (left != null)
				this.$refs.popup.style.left = `${left}px`;
		}
	},

	mounted()
	{
		this.$nextTick(() => this.fixTooltipPosition());
	}
}
</script>