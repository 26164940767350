<template>
	<div>
		<ui-panel-body>
			<!-- DISPLAY IMAGE CROPPER IF EDITING -->
			<template v-if="editing">
				<image-cropper @change="updatePhotoInfo" stencil="circle" />
			</template>

			<!-- DISPLAY PREVIEW OF CURRENT PROFILE PHOTO -->
			<template v-else-if="user.photo_url">
				<div class="photo-preview">
					<img :src="user.photo_url">
				</div>
			</template>

			<!-- OTHERWISE DISPLAY MESSAGE BACK LACK OF PROFILE PHOTO -->
			<template v-else>
				No profile photo present
			</template>

		</ui-panel-body>

		<ui-panel-footer>

			<template v-if="!editing">
				<ui-button :block="$breakpoints.smAndDown" :class="{ 'mb-2': $breakpoints.smAndDown }" color="primary" icon="fas fa-upload" @click="setNewPhoto">Set New Photo</ui-button>
				<span class="flex-spacer"/>
				<ui-button v-if="user.photo_url" :block="$breakpoints.smAndDown" :class="{ 'mb-2': $breakpoints.smAndDown }" color="danger" icon="fas fa-trash" :loading="deleting" @click="deletePhoto">Delete Photo</ui-button>
			</template>

			<template v-else>
				<ui-button :block="$breakpoints.smAndDown" :class="{ 'mb-2': $breakpoints.smAndDown }" color="primary" icon="fas fa-upload" :loading="saving" :disabled="photoInfo == null" @click="savePhoto">Save Profile Photo</ui-button>
				<span class="flex-spacer"/>
				<ui-button :block="$breakpoints.smAndDown" :class="{ 'mb-2': $breakpoints.smAndDown }" color="warning" icon="fas fa-times-circle" :disabled="saving" @click="clearPhoto">Cancel</ui-button>
			</template>
		</ui-panel-footer>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import api from 'igolf/users/api';
import alerts from 'igolf/shared/alerts';
import ImageCropper from 'igolf/shared/comp/ImageCropper.vue';

const MAX_WIDTH = 500;
const MAX_HEIGHT = 500;

export default {
	components: { ImageCropper },

	data() {
		return {
			photoInfo: null,
			editing: false,
			deleting: false,
			saving: false,
		}
	},

	computed: {
		...mapState({
			user: state => state.shell.currentUser,
		}),
	},

	methods: {
		setNewPhoto()
		{
			this.editing = true;
			this.photoInfo = null;
		},

		clearPhoto()
		{
			this.editing = false;
			this.photoInfo = null;
		},

		updatePhotoInfo(info)
		{
			this.photoInfo = { ...info };
		},

		async savePhoto()
		{
			if (this.photoInfo == null)
				return;
				
			this.saving = true;

			try
			{
				var result;
				var formData = new FormData();
				var { left, top, width, height } = this.photoInfo.coordinates;

				formData.append('photo_file', this.photoInfo.imageFile);
				formData.append('crop', [left, top, width, height].join(','));

				var result = await api.post('/profile/photo', formData);
				await alerts.show('Profile photo updated successfully!', 'Success', 'success');

				this.$store.commit('shell/updateCurrentUser', { photo_url: result.data.photo_url });

				this.clearPhoto();
			}
			finally
			{
				this.saving = false;
			}
		},

	}
}
</script>