<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">
			<div class="title">Messages</div>

			<conversation-list />
		</div>
	</div>
</template>

<script>
import ConversationList from './ConversationList.vue';

export default {
	components: { ConversationList }	
}
</script>