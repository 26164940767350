
import store from '../store';

// function to modify routes with navigation guard
export function addRequireUserGuard(routes)
{
	return routes.map(r => ({ 
		...r,
		beforeEnter(to, from, next) {
			if (store.state.shell.currentUser == null)
				document.location = '/login';
			else
				next();
		}
	}));
}

// mixin to add a navigation guard to prevent unauthorized access to screens
export const requireUserMixin =  {
	beforeRouteEnter(to, from, next)
	{
		if (store.state.shell.currentUser == null)
			document.location = '/login';
		else
			next();
	},

	beforeRouteUpdate(to, from, next)
	{
		if (store.state.shell.currentUser == null)
			document.location = '/login';
		else
			next();
	}
}