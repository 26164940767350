<template>
	<ui-panel>
		<ui-panel-body v-if="lock && lockedByOther">
			<div class="caption">Locked by other user</div>
			<div class="small-text">This teetime is currently held by another user</div>
		</ui-panel-body>

		<ui-panel-body v-else-if="lock && !lockedByOther">
			<div class="caption">Time remaining: {{ timeLeft }}</div>
			<div class="small-text">
				This tee time will be held for {{ duration }} minutes. If you do not complete your reservation within the allotted time, your tee time will be made available to other members.
			</div>
		</ui-panel-body>
	</ui-panel>
</template>

<script>
import _ from 'lodash/string';
import moment from 'moment';
import { mapState } from 'vuex';

import alerts from 'igolf/shared/alerts';

export default {
	inject: ['$setLoadingCover'],

	data()
	{
		return {
			interval: null,
			timeLeft: '',
		}
	},

	computed: {
		...mapState({
			course: state => state.teesheet.course,
			date: state => state.teesheet.date,
			lock: state => state.teesheet.teetimeLock.lock,
			lockedByOther: state => state.teesheet.teetimeLock.locked,
			reservation: state => state.teesheet.reservation,
		}),

		duration()
		{
			var lockedFrom = moment(this.lock.locked_from);
			var lockedTo = moment(this.lock.locked_to);
			return lockedTo.diff(lockedFrom, 'minutes');
		},
	},

	methods: {
		startCountdown()
		{
			this.interval = setInterval(() => this.tick(), 1000);
			this.tick();
		},

		async tick()
		{
			var lockedFrom = moment();
			var lockedTo = moment(this.lock.locked_to);
			var duration = moment.duration(lockedTo.diff(lockedFrom));
			var msg = 'Your tee time reservation period has expired as the tee time been made available to other members. Select "OK" to request additional time to make your reservation.';
			
			this.timeLeft = `${_.padStart(duration.minutes(), 2, 0)}:${_.padStart(duration.seconds(), 2, '0')}`;

			if (duration.seconds() < 0)
			{
				let returnUrl = `/teesheet/${this.course.url_slug}/${this.date}`;;
				let lockResult;

				// stop countdown
				clearInterval(this.interval);

				// prompt user
				await alerts.show(msg, 'Teetime Hold Expired', 'warning');

				// try to create another lock
				this.$setLoadingCover(true, 'Please wait...');
				try
				{
					lockResult = await this.$store.dispatch('teesheet/fetchTeetimeLock', this.reservation.timeOf);
					this.$setLoadingCover(false);

					// if somebody else came in before we hit ok then alert user and redirect back to teesheet
					if (lockResult.locked)
					{
						this.$router.push(returnUrl);
						alerts.show('This teetime is no longer available', 'Sorry', 'error');
					}
					// otherwise resume countdown
					else
					{
						this.startCountdown();
					}
				}
				catch(err)
				{
					this.$setLoadingCover(false);
					this.$router.push(returnUrl);
				}
			}
		}
	},

	mounted()
	{
		if (!this.lockedByOther)
			this.startCountdown();
	},

	beforeDestroy()
	{
		clearInterval(this.interval);
	}
}
</script>