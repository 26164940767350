<template>
	<ui-form-field v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value">
		<div class="radio-list vert">
			<ui-radio
				v-for="item in items"
				:disabled="disabled"
				:key="item[itemValue]"
				:value="item[itemValue]"
				:data="value"
				:label="item[itemText]"
				@input="$emit('input', $event)"
			/>
		</div>
	</ui-form-field>
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	inject: ['$addToValidation'],

	props: { 
		label: { type: String, default: '' },
		value: { default: '' },
		items: { type: Array, default: () => ([]) },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
	}
}
</script>