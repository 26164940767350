
import Vue from 'vue';
import PanelTitle from './comp/PanelTitle.vue';

Vue.component('UiPanel', {
	render(h)
	{
		return h('div', { class: 'panel' }, this.$slots.default);
	}
});

Vue.component('UiPanelHeader', {
	props: {
		sizeType: { type: String, default: 'aspect' },
		height: { type: Number, default: 100 },
		aspectRatio: { type: Number, default: 9/16 },
		src: { type: String, default: '' },
	},

	render(h) {
		var options = {
			class: 'panel-header',
			style: { },
		};

		if (this.sizeType == 'aspect')
			options.style['padding-bottom'] = Math.round(this.aspectRatio * 100)+'%';
		else if (this.sizeType == 'height')
			options.style.height = `${this.height}px`;

		if (this.src)
			options.style['background-image'] = `url(${this.src})`;

		return h('div', options, this.$slots.default);
	}
});

Vue.component('UiPanelHeaderIcon', {
	props: {
		size: { type: Number, required: true },
		src: { type: String, required: true },
	},

	render(h)
	{
		var options = {
			class: 'panel-header-icon',
			style: {
				'background-image': `url(${this.src})`,
				width: `${this.size}px`,
				height: `${this.size}px`,
			},
		};

		return h('div', options);
	}
});

/*Vue.component('UiPanelTitle', {
	props: {
		collapsed: { type: Boolean, default: null },
	},

	render(h) {
		var opts = {
			class: 'panel-title',
		};

		if (this.collapsed)

		return h('div', { class: 'panel-title' }, this.$slots.default);
	}
});*/

Vue.component('UiPanelBody', {
	render(h) {
		return h('div', { class: 'panel-body' }, this.$slots.default);
	}
});

Vue.component('UiPanelFooter', {
	render(h) {
		return h('div', { class: 'panel-footer' }, this.$slots.default);
	}
});

Vue.component('UiPanelTitle', PanelTitle);