
import 'sweetalert2/src/sweetalert2.scss'

import swal from 'sweetalert2';

export default {
	async show(text, title, type)
	{
		await swal.fire(title, text, type);
	},

	async confirm(text, title, type, yesno)
	{
		var result, params = { text, title, icon: type || 'question', showCancelButton: true };

		if (yesno)
		{
			params = { 
				...params,
				cancelButtonText: 'No',
				confirmButtonText: 'Yes',
			}
		}

		result = await swal.fire(params);
		return result.value ? true : false;
	}
}