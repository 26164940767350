<template>
	<div class="calendar">
		<template v-if="showDate == null">
			<calendar-main v-if="view == 'main'" v-model="date" :check-date="pureCheckDate" @selected="selected" :hide-trailing="hideTrailing"></calendar-main>
			
			<calendar-months v-else-if="view == 'months'" v-model="date" :show-date="showDate"></calendar-months>
			
			<calendar-years v-else-if="view == 'years'" v-model="date"></calendar-years>
		</template>

		<calendar-main v-else v-model="date" :show-date="showDate" :check-date="pureCheckDate"  :hide-trailing="hideTrailing" @selected="selected"></calendar-main>
	</div>
</template>

<script>
import moment from 'moment';

import CalendarMain from './CalendarMain.vue';
import CalendarMonths from './CalendarMonths.vue';
import CalendarYears from './CalendarYears.vue';

export default {
	components: { CalendarMain, CalendarMonths, CalendarYears },

	props: {
		value: { type: String, default: '' },
		format: { type: String, default: 'YYYY-MM-DD' },
		minDate: { type: String, default: null },
		maxDate: { type: String, default: null },
		showDate: { type: String, default: null },
		checkDate: { type: Function, default: null },
		hideTrailing: { type: Boolean, default: false },
	},

	provide()
	{
		return {
			'$setParentView': this.setView,
		}
	},

	data()
	{
		return {
			view: 'main',
			//date: { year: '', month: '', date: '' },
			pureCheckDate: null,
		}
	},

	computed: {
		date: {
			get()
			{
				var date = this.value ? moment(this.value) : moment();
				return {
					year: date.year(),
					month: date.month(),
					date: date.date(),
				};
			},

			set(val)
			{
				var date = moment(val);
				this.$emit('input', date.format(this.format));
			}
		}
	},

	methods: {
		setView(v)
		{
			this.view = v;
		},

		getPureCheckDate()
		{
			var min = this.minDate ? moment(this.minDate, 'YYYY-MM-DD') : null;
			var max = this.maxDate ? moment(this.maxDate, 'YYYY-MM-DD') : null;
			var checkDate = this.checkDate;

			return function(date) {
				if (checkDate != null)
					return checkDate(date);
				else if (min && !date.isSameOrAfter(min))
					return false;
				else if (max && !date.isSameOrBefore(max))
					return false;
				else
					return true;
			}
		},

		selected()
		{
			this.$emit('selected');
		}
	},

	created()
	{
		//var date = this.value ? moment(this.value, this.format) : moment();

		// create pure date checker function
		this.pureCheckDate = this.getPureCheckDate();

		// initialize date components
		/*this.date = {
			year: date.year(),
			month: date.month(),
			date: date.date(),
		};*/
	},
}
</script>