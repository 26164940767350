<template>
	<div>
		<ui-data-table
			:loading="loading"
			:hide-header="$breakpoints.smAndDown"
			:compact="$breakpoints.smAndDown"
			:headers="$breakpoints.smAndDown ? smallHeaders : headers"
			:items="contactList"
			empty-text="No contacts found">

			<template v-slot:row="{ item }">
				<td class="is-buttons">
					<ui-icon :to="`/contacts/${item.id}`" color="primary" icon="fas fa-edit"/>
					<ui-icon color="danger" icon="fas fa-trash" @click="deleteContact(item)"/>
				</td>
				<td v-if="!$breakpoints.smAndDown">
					<ui-checkbox
					 	hide-label
						:disabled="loading"
						:data="item.is_primary" 
						@input="setPrimary(item, $event)"
					/>
				</td>
				<td>{{ item.name }}</td>
				<td v-if="!$breakpoints.smAndDown">{{ item.email }}</td>
				<td v-if="!$breakpoints.smAndDown">{{ item.phone }}</td>
			</template>

		</ui-data-table>

		<ui-panel-footer>
			<ui-button color="primary" icon="fas fa-plus" to="/contacts/new">Add New Contact</ui-button>
		</ui-panel-footer>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';
import api from 'igolf/users/api';

export default {
	data()
	{
		return {
			loading: false,

			headers: [
				{ name: '', value: 'id', sortable: false, class: 'is-buttons', style: { width: '100px' } },
				{ name: 'Primary', value: 'primary', sortable: false, style: { width: '100px' } },
				{ name: 'Name', value: 'name', sortable: false },
				{ name: 'Email', value: 'email', sortable: false },
				{ name: 'Phone', value: 'phone', sortable: false },
			],

			smallHeaders: [
				{ name: '', value: 'id', sortable: false, class: 'is-buttons', style: { width: '70px' } },
				{ name: 'Name', value: 'name', sortable: false },
			]
		}
	},

	computed: {
		...mapGetters('profile', ['contactList']),
	},

	methods: {
		async refreshList()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('profile/fetchContactList');
			}
			finally
			{
				this.loading = false;
			}
		},

		async setPrimary(contact, checked)
		{
			// make sure the checkbox is checked and that the contact isn't already primary
			if (!contact.is_primary && checked)
			{
				this.loading = true;

				try
				{
					// update contact on server
					var data = { is_primary: true };
					await api.put(`/contacts/${contact.id}`, data);

					// refresh contact list
					await this.refreshList();
				}
				finally
				{
					this.loading = false;
				}
			}
		},

		async deleteContact(contact)
		{
			if (contact.is_primary)
			{
				await alerts.show('You cannot delete your primary contact', 'Error', 'warning');
				return;
			}

			if (!await alerts.confirm('Are you sure you want to remove this contact?', 'Delete Contact', 'question'))
				return;

			this.loading = true;

			try
			{
				await api.delete(`/contacts/${id}`);
				await this.refreshList();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshList();
	}
}
</script>