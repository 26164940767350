<template>
	<ui-panel>
		<ui-data-table
			empty-text="No conversations found"
			:total-rows="total"
			:pagination.sync="pagination"
			:loading="loading"
			:headers="headers"
			:items="conversationList">

			<template v-slot:row="{ item }">
				<td class="is-buttons">
					<ui-icon color="primary" icon="fas fa-comments" :to="`/conversation/${item.id}`"/>
					<ui-icon color="danger" icon="fas fa-trash"/>
				</td>
				<td>{{ item.started_by }}</td>
				<td>{{ item.participants.join(', ') }}</td>
				<td>{{ (item.last_sent_date || item.started_on) | dateFormat('MMM Do, YYYY @ h:mma') }}</td>
			</template>
		</ui-data-table>

		<ui-panel-footer>
			<ui-button color="primary" to="/conversation/new">Send Message</ui-button>
		</ui-panel-footer>
	</ui-panel>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import filters from 'igolf/shared/filters';

export default {
	mixins: [filters],

	data()
	{
		return {
			loading: false,
			total: 0,

			pagination: {
				page: 0,
				perPage: 10,
			},

			headers: [
				{ name: '', value: 'id', sortable: false, class: 'is-buttons', style: { width: '100px' } },
				{ name: 'Started By', value: 'started_by', sortable: false },
				{ name: 'Participants', value: 'Participants', sortable: false },
				{ name: 'Last Message Sent', value: 'last_sent_date', sortable: false },
			]
		}
	},

	computed: {
		...mapGetters('messages', ['conversationList']),
	},

	watch: {
		pagination: { 
			deep: true,
			handler() {
				this.refreshList();
			}
		},

		listType()
		{
			this.refreshList();
		}
	},

	methods: {
		...mapActions('messages', ['fetchConversationList']),

		async refreshList()
		{
			this.loading = true;

			try
			{
				var result;
				var params = { 
					page: this.pagination.page,
					per_page: this.pagination.perPage,
					list_type: this.listType,
				};

				result = await this.fetchConversationList(params);

				this.total = result.total;
			}
			finally
			{
				this.loading = false;
			}
		},

		getConversationLink(msg)
		{
			return '';
		}
	},

	mounted()
	{
		this.refreshList();
	}
}
</script>