<template>
	<ul class="table-pagination">
		<!-- FIRST/PREVIOUS LINKS -->
		<template v-if="page > 0">
			<li>
				<a href="#" class="page-link" role="button" aria-label="First Page" @click.prevent="setPage(0)"><i class="fas fa-angle-double-left"/></a>
			</li>
			<li>
				<a href="#" class="page-link" role="button" aria-label="Previous Page" @click.prevent="setPage(page-1)"><i class="fas fa-angle-left"/></a>
			</li>
		</template>

		<template v-else>
			<li>
				<span class="page-link is-disabled"><i class="fas fa-angle-double-left"/></span>
			</li>
			<li>
				<span class="page-link is-disabled"><i class="fas fa-angle-left"/></span>
			</li>
		</template>

		<!-- SINGLE PAGE LINKS -->
		<li v-for="p in pageNumbers" :key="p">
			<a href="#" :class="{ 'page-link': true, 'is-current': p == page }" role="button" :aria-label="`Page #${p+1}`" @click.prevent="setPage(p)">{{ p+1 }}</a>
		</li>

		<!-- NEXT/LAST LINKS -->
		<template v-if="page+1 < total">
			<li>
				<a href="#" class="page-link" role="button" aria-label="Next Page" @click.prevent="setPage(page+1)"><i class="fas fa-angle-right"/></a>
			</li>
			<li>
				<a href="#" class="page-link" role="button" aria-label="Last Page" @click.prevent="setPage(total-1)"><i class="fas fa-angle-double-right"/></a>
			</li>
		</template>

		<template v-else>
			<li>
				<span class="page-link is-disabled"><i class="fas fa-angle-right"/></span>
			</li>
			<li>
				<span class="page-link is-disabled"><i class="fas fa-angle-double-right"/></span>
			</li>
		</template>
	</ul>
</template>

<script>
const MAX_PAGES = 6;

export default {
	props: {
		page: { type: Number, required: true },
		total: { type: Number, required: true },
	},

	computed: {
		pageNumbers()
		{
			var nums = [];
			var current = this.page;
			var start, stop;

			if (current < MAX_PAGES)
			{
				start = 0;
				stop = MAX_PAGES-1;
			}
			else if (current >= MAX_PAGES)
			{
				start = (current - MAX_PAGES)+1;
				stop = current;

			}

			for(var i=start; i<=stop && i<this.total; i++)
				nums.push(i);

			return nums;
		}
	},

	methods: { 
		setPage(p)
		{
			this.$emit('update:page', parseInt(p, 10) || 0);
		}
	}
}
</script>