<template>
	<button type="button" class="menubar-item" :class="itemClasses" @click.prevent="clicked">
		<slot name="icon-left">
			<span v-if="iconLeft" class="menubar-item-icon"><i :class="iconLeft"></i></span>
		</slot>

		<span class="menubar-item-text">
			<slot>{{ label }}</slot>
		</span>

		<slot name="icon-right">
			<span v-if="iconRight" class="menubar-item-icon"><i :class="iconRight"></i></span>
		</slot>
	</button>
</template>

<script>
import clickHandler from 'igolf/ux/mixins/click-handler';

export default {
	mixins: [clickHandler],

	props: {
		loading: { type: Boolean, default: false },
		label: { type: String },
		iconLeft: { type: String, default: null },
		iconRight: { type: String, default: null },
		primary: { type: Boolean, default: false },
		primaryDark: { type: Boolean, default: false },
		danger: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},

	computed: { 
		itemClasses()
		{
			return {
				'is-primary': !this.disabled && this.primary,
				'is-primary-dark': !this.disabled && this.primaryDark,
				'is-danger': !this.disabled && this.danger,
				'is-disabled': this.disabled,
			}
		}
	},

	methods: {
		clicked(event)
		{
			if (!this.disabled)
				this.handleClick(event);
		}
	}
}
</script>