<template>
	<div>
		<ui-panel-body>
			<ui-form ref="form">
				<div class="row">
					<div class="col-md-6">
						<ui-select-field
							label="Cart Preference"
							empty-text="None"
							:empty-value="0"
							:items="cartList"
							item-text="name"
							item-value="id"
							v-model="user.pref_cart_id" 
						/>
					</div>
				</div>

				<div class="row">
					<div class="col-md-6">
						<ui-text-box-field
							label="First Name"
							v-model="user.first_name"
							:rules="rules.first_name">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							label="Last Name"
							v-model="user.last_name"
							:rules="rules.last_name">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							label="Primary Email"
							v-model="user.primary_email"
							:rules="rules.primary_email">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							label="Primary Phone"
							v-model="user.primary_phone">
						</ui-text-box-field>
					</div>

					<div class="col-xs-12">
						<ui-text-box-field
							label="Username"
							v-model="user.username"
							:rules="rules.username">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							password
							label="Password"
							v-model="user.password"
							:rules="rules.password">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							password
							label="Confirm Password"
							v-model="user.password_confirmation"
							:rules="rules.password_confirmation">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-select-field
							label="Gender"
							:items="genderOptions"
							v-model="user.gender"
							:rules="rules.gender">
						</ui-select-field>
					</div>

					<div class="col-md-6">
						<ui-date-picker
							label="Date Of Birth"
							format="YYYY-MM-DD"
							v-model="user.date_of_birth"
							:rules="rules.date_of_birth">
						</ui-date-picker>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							label="Handicap Number"
							v-model="user.handicap_number">
						</ui-text-box-field>
					</div>

					<div class="col-md-6">
						<ui-text-box-field
							label="Bag Number"
							v-model="user.bag_number">
						</ui-text-box-field>
					</div>
				</div>		
			</ui-form>
		</ui-panel-body>

		<ui-panel-footer>
			<ui-button color="primary" :loading="saving" @click="save">Update Profile</ui-button>
		</ui-panel-footer>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import validate from 'igolf/shared/validators';

import api from 'igolf/users/api';
import alerts from 'igolf/shared/alerts';

export default {
	data()
	{
		return {
			user: {},
			saving: false,

			genderOptions: [
				{ text: '(select gender)', value: '' },
				{ text: 'Male', value: 'male' },
				{ text: 'Female', value: 'female' },
				{ text: 'Non-specified / other', value: 'other' },
			],
			
			rules: {
				first_name: [ v => !!v || 'Please enter your first name' ],
				last_name: [ v => !!v || 'Please enter your last name' ],
				username: [ v => !!v || 'Please enter your username' ],
				gender: [ v => !!v || 'Please select a gender' ],
				date_of_birth: [ v => !!v || 'Please select your date of birth' ],

				primary_email: [
					v => !!v || 'Please enter the primary email address',
					v => validate.email(v) || 'Invalid email address',
				],
				
				password: [ 
					v => (v == '') || !!v || 'Please enter a password',
					v => (v == '') || validate.password(v, 6) || 'Passwords must be at least six characters long',
				],

				password_confirmation: [
					v => v == this.user.password || 'Passwords do not match!',
				],
			}
		}
	},
	
	computed: {
		...mapGetters('shell', ['cartList']),
		...mapState({
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				await api.post('/profile/update', { profile: this.user });
				await alerts.show('Profile updated successfully', 'Success', 'success');

				// force app to reload profile
				document.location = '/users/profile';
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	created()
	{
		this.user = { ...this.currentUser, password: '', password_confirmation: '' };
	}
}
</script>