
import api from 'igolf/users/api';

export default {
	async fetchConversationList({ commit }, params)
	{
		var result = await api.get('/conversations', { params });
		commit('setConversationList', result.data.conversations);
		return result.data;
	},

	async fetchMessageList({ commit }, { conversationId, params, appending })
	{
		var result = await api.get(`/conversations/${conversationId}/messages`, { params });

		if (appending)
			commit('appendMessages', result.data.messages);
		else
			commit('setMessageList', result.data.messages);

		return result.data;
	},

	async fetchConversation({ commit }, id)
	{
		var result = await api.get(`/conversations/${id}`);
		commit('setConversation', result.data.conversation);
	}
}