
import router from 'igolf/users/router';

import CourseOptions from './comp/CourseOptions.vue';

router.addRoutes([
	{
		name: 'users-course-options',
		path: '/courses',
		component: CourseOptions,
	}
]);