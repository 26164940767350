<template>
	<ui-menu :show.sync="visible" :close-on-content-click="false" position="bottom-left">
		<template v-slot:activator="{ on }">
			<ui-form-field ref="field" v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value">
				<input 
					v-on="on"
					type="text"
					class="field-control"
					:class="{ 'is-compact': compact }"
					:value="value"
					:disabled="disabled"
					readonly
					@blur="setFocus(false)"
					@focus="setFocus(true)"
				>
			</ui-form-field>
		</template>

		<ui-panel class="is-floating" style="width: 316px;">
			<div class="datepicker-wrapper">
				<ui-calendar 
					:value="value"
					@input="$emit('input', $event)"
					@selected="visible = false">
				</ui-calendar>
			</div>
		</ui-panel>
	</ui-menu>
</template>

<script>
import outsideClick from 'igolf/shared/outside-click';

import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps, outsideClick],
	inject: ['$addToValidation'],

	props: { 
		value: { type: String, default: '' },
		format: { type: String, default: 'YYYY-MM-DD' }
	},

	data()
	{
		return {
			visible: false,
			/*checkDate: function(date) {
				return (date.day() < 6);
			}*/
		}
	},

	methods: {
		setVisible(flag)
		{
			this.visible = flag;
			if (!this.visible)
				this.setFocus(false);
		},
	},

	mounted()
	{
		this.$on('outside-click', () => this.visible = false);
	}

}
</script>

<style scoped>
.datepicker-wrapper
{
	padding: 8px;
	width: 300px;
}

.ui-panel
{
	width: 316px !important;
}
</style>