<template>
	<div>
		<!-- display navigation if no display date override -->
		<div v-if="showDate == null" class="calendar-navigation">
			<button class="btn-text is-icon" type="button" @click.prevent="updateMonth(-1)"><i class="fas fa-chevron-left"/></button>
			<button class="calendar-title" type="button" @click.stop="$setParentView('months')">{{ title }}</button>
			<button class="btn-text is-icon" type="button" @click.prevent="updateMonth(1)"><i class="fas fa-chevron-right"/></button>
		</div>

		<!-- do not allow navigation if display date override is set -->
		<div v-else class="calendar-navigation">
			<span class="calendar-title">{{ title }}</span>
		</div>

		<ul class="calendar-header">
			<li>Sun</li>
			<li>Mon</li>
			<li>Tue</li>
			<li>Wed</li>
			<li>Thu</li>
			<li>Fri</li>
			<li>Sat</li>
		</ul>

		<ul class="calendar-days">
			<li v-for="date in dates" :key="date.dateStr">
				<template v-if="!hideTrailing || !date.isTrailing">
					<button v-if="date.valid" class="calendar-day-btn" :class="date.classes" type="button" @click.prevent="selectDate(date.dateObj)">{{ date.day }}</button>
					<button v-else class="calendar-day-btn" disabled type="button">{{ date.day }}</button>
				</template>
			</li>
		</ul>
	</div>
</template>

<script>
import moment from 'moment';

const SHOW_DATE_FORMAT = 'YYYY-MM-DD';

export default {
	model: { prop: 'date', event: 'input' },
	inject: ['$setParentView'],

	props: { 
		date: { type: Object, required: true }, 
		showDate: { type: String, default: null },
		checkDate: { type: Function, default: null },
		hideTrailing: { type: Boolean, default: false },
	},

	computed:
	{
		displayDate()
		{
			return this.showDate ? moment(this.showDate, SHOW_DATE_FORMAT) : moment(this.date);
		},

		title()
		{
			return this.displayDate.format('MMMM YYYY');
		},

		dates()
		{
			var display = moment(this.displayDate);
			var selected = moment(this.date).format('YYYY-MM-DD');
			var start = moment(this.displayDate).startOf('month').startOf('week');
			var end = moment(this.displayDate).endOf('month').endOf('week');
			var current = moment(start);
			var result = [];

			while(current.isSameOrBefore(end))
			{
				var date = {
					dateObj: { year: current.year(), month: current.month(), date: current.date() },
					dateStr: current.format('YYYY-MM-DD'),
					day: current.date(),
					classes: [],
					valid: this.checkDate != null ? this.checkDate(current) : true,
					isTrailing: current.month() != display.month(),
				};

				if (date.dateStr == selected)
					date.classes.push('is-selected');

				if (!this.hideTrailing && date.isTrailing)
					date.classes.push('is-trailing');

				result.push(date);
				current.add(1, 'days');
			}

			return result;
		}
	},

	methods: {
		updateMonth(dir)
		{
			var date = moment(this.date);

			date.add(dir, 'months');

			this.$emit('input', {
				year: date.year(),
				month: date.month(),
				date: date.date(),
			});

			/*-// copy current date value
			var date = { ...this.date };
			
			// move backwards/forwards by a month
			date.month += dir;
			
			// move year if neccessary
			if (date.month < 0)
			{
				date.month = 11;
				date.year--;
			}
			else if (date.month >= 12)
			{
				date.month = 0;
				date.year++;
			}

			// send date to parent
			this.$emit('input', date);*/
		},

		selectDate(date)
		{
			this.$emit('input', { ...date });
			this.$emit('selected');
		}
	},
}
</script>