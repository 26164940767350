

/*
export function mapList(arrayName, list)
{
	var data = {
		ids: [],
		[arrayName]: {},
	};

	data.ids = list.map(obj => {
		data[arrayName][obj.id] = { ...obj };
		return obj.id;
	});

	return data;
}
*/

export function mapList()
{
	var arrayName, list;

	if (arguments.length == 1)
	{
		arrayName = 'objects';
		list = arguments[0];
	}
	else
	{
		arrayName = arguments[0];
		list = arguments[1];
	}

	var data = {
		ids: [],
		[arrayName]: {},
	};

	data.ids = list.map(obj => {
		data[arrayName][obj.id] = { ...obj };
		return obj.id;
	});

	return data;
}


export function slugify(text, noTrim)
{
	var slug = text;
	
	// trim whitespace
	if (!noTrim)
		slug = slug.replace(/^\s+|\s+$/g, '');
	
	// convert whitespace to underscores
	slug = slug.replace(/\s/g, '-');

	// replace repeated instances of - with a single -
	slug = slug.replace(/-+/g, '-');

	// remove non URL safe characters
	slug = slug.replace(/[^\w\d-_]/g, '');
	
	// make slug lower case and return
	return slug.toLowerCase();
}

export function generateTimeOptions(intervals, earliest, latest)
{
	var time = earliest;
	var end = latest;
	var current = 0;
	var options = [];
	var total = 0;

	for(var m of intervals)
		total += parseInt(m, 10);
	
	// prevent infinite loops
	if (total == 0)
		return [{ text: 'Invalid interval setup', value: '' }];

	while(time.isSameOrBefore(end))
	{
		// get minute interval to use
		let mins = parseInt(intervals[current], 10);

		// move to next interval with wrapping
		current++
		if (current >= intervals.length)
			current = 0;
		
		// if this interval is 0 or less then skip this iteration
		if (mins <= 0)
			continue;

		// add option
		options.push({
			text: time.format('h:mma'),
			value: time.format('HH:mm'),
		});

		time.add(mins, 'minutes');
	}

	return options;

}