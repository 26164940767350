
export default {
	'primary': '#0b5e8f',
	'secondary': '#b6bac3',
	'info': '#4aa1ca',
	'success': '#63b839',
	'warning': '#ff6000',
	'danger': '#e10f0f',
	'grey-darker': '#202020',
	'grey-dark': '#404040',
	'grey': '#909090',
	'grey-light': '#c0c0c0',
	'grey-lighter': '#e0e0e0',
	'grey-lightest': '#f4f4f4',
}