<template>
	<div>
		<div class="shell-header-top no-banner"></div>
		<div class="container">
			<div class="title">Upcoming Teetimes/Lottery Entries</div>

			<bookings />

			<lottery-entries/>
		</div>
	</div>
</template>

<script>
import Bookings from './Bookings.vue';
import LotteryEntries from './LotteryEntries.vue';

export default {
	components: { Bookings, LotteryEntries },
}
</script>