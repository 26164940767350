<template>
    <div>
        <div class="container-fluid" v-if="loading || course == null">
            Loading...
        </div>

        <template v-else>
			<ui-menubar v-if="saving" frontend>
				<ui-menubar-item label="Saving..." icon-left="fas fa-sync fa-spin"/>
			</ui-menubar>
			<ui-menubar v-else frontend>
                <ui-menubar-item primary label="Save Booking" icon-left="fas fa-save" :disabled="lock && lock.locked" @click="save" />
                <ui-menubar-item label="Back To Teesheet" icon-left="fas fa-arrow-circle-left" :to="returnUrl" />
			</ui-menubar>

            <div class="container">
				<div class="title">Reservation Details</div>

				<!-- MAIN BOOKING FORM AREA -->
				<div class="booking-form-row">
					<div class="col-md-4">
						<!-- BOOKING DETAILS -->
						<booking-details/>

						<!-- INTERFACE NOTES -->
						<div v-for="note in interfaceNotes" :key="note.id" v-html="note.note_content" />
					</div>
					<div class="col">
						<!-- DISPLAY LOCK INFO -->
						<booking-lock-countdown v-if="lock" />

						<ui-panel>
							<ui-panel-body>
								<ui-form ref="form">
									<ui-select-field
										label="Number of players"
										:items="numPlayerOptions"
										v-model="numPlayers"
									/>

									<div class="row">
										<div class="col-md-6">
											<ui-text-box-field 
												readonly 
												label="Booking Player"
												:value="`${currentUser.first_name} ${currentUser.last_name}`" 
											/>
										</div>

										<div class="col-md-6">
											<ui-select-field
												label="Cart"
												empty-text="No Cart"
												:empty-value="0"
												:items="cartOptions"
												:value="reservation.cartPref"
												@input="updateReservationDetails({ cartPref: $event })"
											/>
										</div>
									</div>

									<public-player-guests v-if="course.allow_player_fill" />

									<ui-text-area-field
										label="Notes for proshop"
										:value="reservation.bookingNotes"
										@input="updateReservationDetails({ bookingNotes: $event })"
									/>
								</ui-form>
							</ui-panel-body>

							<ui-panel-footer v-if="$breakpoints.mdAndUp">
								<ui-button v-if="!lock || !lock.locked" color="primary" :loading="saving" @click="save">Submit Booking</ui-button>
								<ui-button color="text" :disabled="saving" :to="returnUrl">Back</ui-button>
							</ui-panel-footer>
						</ui-panel>
					</div>
				</div>
            </div>

        </template>
	</div>
</template>

<script>
import moment from 'moment';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';
import filters from 'igolf/shared/filters';

import BookingDetails from './BookingDetails.vue';
import BookingLockCountdown from './BookingLockCountdown.vue';
import PublicPlayerGuests from './PublicPlayerGuests.vue';

export default {
	components: { BookingDetails, BookingLockCountdown, PublicPlayerGuests },

	props: {
		id: { type: String, required: true },
	},

	data()
	{
		return {
			loading: true,
			saving: false,
		}
	},

	computed: {
		...mapGetters('teesheet', ['teetimeBookers']),
		...mapGetters('shell', ['cartList']),

		...mapState({
			currentUser: state => state.shell.currentUser,
			course: state => state.teesheet.course,
			date: state => state.teesheet.date,
			teetimes: state => state.teesheet.teetimes,
			lock: state => state.teesheet.teetimeLock,
			reservation: state => state.teesheet.reservation,
			interfaceNotes: state => state.shell.interfaceNotes.teetime || [],
		}),

		returnUrl()
		{
			return `/teesheet/${this.course.url_slug}/${this.date}`;
		},

		numPlayers: {
			get()
			{
				return this.reservation.publicGuests + 1;
			},

			set(val)
			{
				this.updateReservationDetails({ publicGuests: val - 1 })
			}
		},

		numPlayerOptions()
		{
			var day = parseInt(moment(this.date, 'YYYY-MM-DD').format('d'));
			var min = this.course.public_min_group_size[day] || 1;
			var max = this.course.max_booking_size[day] || this.course.max_players_per_booking;
			var opts = [];

			for(var n=min; n<=max; n++)
				opts.push({ text: `${n} Players`, value: n });

			return opts;
		},

		cartOptions()
		{
			return this.cartList.map(c => ({
				text: `${c.abbrev} (${filters.filters.currency(c.price)})`,
				value: c.id,
			}));
		}

	},

	methods: {
		...mapMutations('teesheet', ['setReservationDetails', 'updateReservationDetails', 'setPublicPlayerGuests']),
		...mapActions('teesheet', [
			'submitPublicReservation', 'fetchTeetimeLock', 'fetchReservationBlock',
		]),


		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				// submit reservation data to server
				await this.submitPublicReservation();

				// redirect back to teesheet
				this.$router.push(this.returnUrl);
			}
			finally
			{
				this.saving = false;
			}
		}
	},

	async mounted()
	{
		if (!this.currentUser)
		{
			await alerts.show('You are not logged in', 'Error', 'error');
			document.location = '/login';
			return;
		}

		if (this.teetimes.rows[this.id])
		{
			var id, row, currentPlayers, defaultPlayers;

			id = this.id;
			row = this.teetimes.rows[id];
			currentPlayers = row.players.length - 1;
			defaultPlayers = this.numPlayerOptions[0].value - 1;

		
			// if row is blocked then punt back to the teesheet
			if (row.block && row.block.is_locked)
			{
				this.$router.push(this.returnUrl);
				alerts.show('This teetime is locked', 'Locked', 'error');
				return;
			}

			// set reservation details
			this.setReservationDetails({
				courseId: this.course.id,
				teetimeId: this.id,
				dateOf: this.date,
				numHoles: (row.block && row.block.num_holes == 9) ? 9 : 18,
				timeOf: row.time,
				hole: row.hole,
				ignoreRestrictions: false,
				squeeze: false,
				squeezeId: 0,
				//publicGuests: this.numPlayerOptions[0].value - 1,
				publicGuests: currentPlayers > defaultPlayers ? currentPlayers : defaultPlayers,
				bookingNotes: '',
				cartPref: row.players.length > 0 ? row.players[0].cart_id : 0,
			});

			// add guests
			if (row.players.length > 1)
			{
				let guests = [];

				for(var i=1; i<row.players.length; i++)
					if (row.players[i].booked_by == this.currentUser.id)
						guests.push({ name: row.players[i].fill_desc, cart_id: row.players[i].cart_id });

				this.setPublicPlayerGuests(guests);
			}

			// get lock info
			await this.fetchTeetimeLock(row.time);

			// load block details
			if (row.block)
				await this.fetchReservationBlock(row.block.id);

			this.loading = false;
		}

	},
}
</script>