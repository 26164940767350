<template>
	<ui-menu :show.sync="visible" :close-on-content-click="false" position="bottom-left">
		<template v-slot:activator="{ on }">
			<ui-form-field ref="field" v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value">
				<input 
					v-on="on"
					type="text"
					class="field-control"
					:class="{ 'is-compact': compact }"
					:value="displayValue"
					:disabled="disabled"
					readonly
				>
			</ui-form-field>
		</template>

		<ui-panel class="is-floating">
			<div class="wrapper">
				<div class="row align-center">
					<div class="col">
						<ui-stepper v-model="hours" />
					</div>
					<div class="col">
						<ui-stepper v-model="minutes" />
					</div>
					<div class="col">
						<div class="form-field no-margin">
							<select v-model="period" class="field-control show-caret">
								<option value="am">AM</option>
								<option value="pm">PM</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</ui-panel>
	</ui-menu>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import outsideClick from 'igolf/shared/outside-click';

import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps, outsideClick],
	inject: ['$addToValidation'],

	props: { 
		value: { type: String, default: '' },
		showPeriod: { type: Boolean, default: true },
	},

	data()
	{
		return {
			visible: false,
		}
	},

	computed: {
		displayValue()
		{
			return this.formatValue(this.showPeriod ? 'h:mma' : 'HH:mm');
		},

		period: {
			get()
			{
				var hours = parseInt(this.formatValue('H'));
				return hours >= 12 ? 'pm' : 'am';
			},

			set(value)
			{
				var hours = parseInt(this.formatValue('H'));
				var mins = this.formatValue('mm');

				if (value == 'am' && hours >= 12)
					hours -= 12;
				else if (value == 'pm' && hours < 12)
					hours += 12;

				hours = _.padStart(hours, 2, '0');
				this.$emit('input', `${hours}:${mins}`);
			}
		},

		hours: {
			get()
			{
				return this.formatValue(this.showPeriod ? 'h' : 'H');
			},

			set(value)
			{
				var hours, mins = this.formatValue('mm');

				if (this.showPeriod)
				{
					value = _.clamp(value, 1, 12);
					if (this.period == 'pm' && value < 12)
						value += 12;
				}
				else
				{
					value = _.clamp(value, 0, 23);
				}

				hours = _.padStart(value, 2, '0');
				this.$emit('input', `${hours}:${mins}`);
			}
		},

		minutes: {
			get()
			{
				return this.formatValue('m');
			},

			set(value)
			{
				var hours = this.formatValue('HH');
				var mins = _.padStart(value, 2, '0');
				this.$emit('input', `${hours}:${mins}`);
			}
		}
	},

	methods: {
		formatValue(format)
		{
			return moment(this.value, 'HH:mm').format(format);
		},

		setVisible(flag)
		{
			this.visible = flag;
			if (!this.visible)
				this.setFocus(false);
		},
	},

	mounted()
	{
		this.$on('outside-click', () => this.visible = false);
	}

}
</script>

<style scoped>
.wrapper
{
	padding: 8px;
	width: 300px;
}
</style>