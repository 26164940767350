<template>
	<ui-form-field v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value" v-on="$listeners">
		<select 
			ref="field"
			class="field-control show-caret" 
			:class="{ 'is-compact': compact }"
			:disabled="disabled"
			@input="$emit('input', $event.target.value)"
			@blur="setFocus(false)"
			@focus="setFocus(true)">

			<option v-if="emptyText" :value="emptyValue">{{ emptyText }}</option>

			<option 
				v-for="item in items"
				:key="item[itemValue]"
				:value="item[itemValue]"
				:selected="item[itemValue] == value">
				{{ item[itemText] }}
			</option>

		</select>
	</ui-form-field>
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	inject: ['$addToValidation'],

	props: { 
		value: { default: '' },
		emptyText: { type: String, default: null },
		emptyValue: { default: '' },
		items: { type: Array, default: () => ([]) },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
	},
}
</script>