<template>
	<ui-form-field 
		ref="formField"
		v-on="$listeners"
		v-bind="formFieldAttrs"
		:focused="hasFocus"
		:validate-value="value"
		>
		<input 
			v-if="!password"
			type="text"
			class="field-control"
			:class="{ 'is-compact': compact }"
			:value="value"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autofocus="autofocus"
			@input="$emit('input', $event.target.value)"
			@blur="setFocus(false)"
			@focus="setFocus(true)"
		>

		<input 
			v-else
			type="password"
			class="field-control"
			:value="value"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autofocus="autofocus"
			@input="$emit('input', $event.target.value)"
			@blur="setFocus(false)"
			@focus="setFocus(true)"
		>
	</ui-form-field>
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	//inject: ['$addToValidation'],

	props: { 
		password: { type: Boolean, default: false },
		autofocus: { type: Boolean, default: false },
		value: { default: '' },
		placeholder: { type: String, default: '' },
		readonly: { type: Boolean, default: false },
	}
}
</script>