
import moment from 'moment';

export default {
	accountInfo: {},
	currentUser: null,
	userClass: null,
	menuVisible: false,
	courses: [],
	coursesBySlug: {},
	coursesById: {},
	carts: {},
	cartIds: [],
	interfaceNotes: {},
}