<template>
	<label class="checkbox" :class="{ 'is-checked': checked, 'is-disabled': disabled, 'no-label': hideLabel }" @click.prevent="toggle">
		<input type="checkbox" :checked="checked" :disabled="disabled" @input="setValue($event.target.checked)">
		<div class="checkbox-graphic"></div>
		<div v-if="!hideLabel" class="checkbox-label">{{ label }}</div>
	</label>
</template>

<script>
export default {
    model: {
        prop: 'data',
        event: 'input',
    },

    props: {
        value: { type: [Boolean, String, Number], default: false },
        data: { type: [Boolean, Array], default: false },
        label: { type: String, default: '' },
        hideLabel: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },

    computed: {
        checked()
        {
            if (this.data instanceof Array)
                return this.data.indexOf(this.value) >= 0;
            else if (typeof(this.data) == 'boolean')
                return this.data;
        },
    },

    methods: {
		setValue(flag)
		{
            // do nothing if disabled
            if (this.disabled) return;

            // toggle value
            if (this.data instanceof Array)
            {
                var newData = this.data.slice();
                var pos = newData.indexOf(this.value);
                
				if (!flag && pos >= 0)
                    newData.splice(pos, 1);
                else if (flag && pos == -1)
                    newData.push(this.value);
                
                this.$emit('input', newData);
            }
            else if (typeof(this.data) == 'boolean')
            {
                this.$emit('input', flag);
            }
		},

		toggle()
		{
            // do nothing if disabled
            if (this.disabled) return;

			this.setValue(!this.checked);
		},

        /*toggle()
        {
            // do nothing if disabled
            if (this.disabled) return;

            // toggle value
            if (this.data instanceof Array)
            {
                var newData = this.data.slice();
                var pos = newData.indexOf(this.value);
                if (pos >= 0)
                    newData.splice(pos, 1);
                else
                    newData.push(this.value);
                
                this.$emit('input', newData);
            }
            else if (typeof(this.data) == 'boolean')
            {
                this.$emit('input', !this.data);
            }
        }*/
    }
}
</script>