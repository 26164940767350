<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">
			<div class="title">My Profile</div>

			<ui-panel>
				<ui-tabs v-model="tab" :tabs="tabs" />

				<transition mode="out-in" name="t-fade">
					<primary-contact-form v-if="tab == 'primary'" key="primary" />

					<contact-list v-if="tab == 'contacts'" key="contacts" />

					<profile-photo v-if="tab == 'photo'" key="photo" />
				</transition>
			</ui-panel>
		</div>
	</div>	
</template>

<script>
import PrimaryContactForm from './PrimaryContactForm.vue';
import ContactList from './ContactList.vue';
import ProfilePhoto from './NewProfilePhoto.vue';

export default {
	components: { PrimaryContactForm, ContactList, ProfilePhoto },

	data()
	{
		return {
			tab: 'primary',
			tabs: [
				{ title: 'Primary Info', value: 'primary' },
				{ title: 'Contact List', value: 'contacts' },
				{ title: 'Profile Photo', value: 'photo' },
			]
		}
	},

	watch: {
		tab() 
		{
			this.$router.replace(`/profile?tab=${this.tab}`)
		}
	},

	created()
	{
		if (this.$route.query.tab && ['primary','contacts'].indexOf(this.$route.query.tab) >= 0)
			this.tab = this.$route.query.tab;
	}
}
</script>