<template>
	<ul ref="list" class="calendar-menu">
		<li v-for="year in years" :key="year">
			<button 
				type="button"
				class="calendar-menu-item" 
				:class="{ 'is-selected': year == date.year }"
				@click.stop="selectYear(year)">
				{{ year }}
			</button>
		</li>
	</ul>
</template>

<script>
export default {
	model: { prop: 'date', event: 'input' },
	inject: ['$setParentView'],

	props: {
		date: { type: Object, required: true },
	},

	data()
	{
		return {
			years: [],
		}
	},

	methods: {
		selectYear(year)
		{
			this.$emit('input', { ...this.date, year });
			this.$setParentView('main');
		}
	},

	created()
	{
		var start = this.date.year - 100;
		var end = this.date.year + 100;
	
		for(var i=start; i<=end; i++)
			this.years.push(i);
	},

	mounted()
	{
		var list = this.$refs.list;
		var sel = list.querySelector('.is-selected');
		list.scrollTop = sel.offsetTop - (list.clientHeight / 2);
	}
}
</script>