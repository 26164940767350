
export default {
	setProfile(state, profile)
	{
		state.profile = { ...profile };
	},

	setContactList(state, list)
	{
		var contacts = {};

		state.contactIds = list.map(c => {
			contacts[c.id] = { ...c };
			return c.id;
		});
		state.contacts = contacts;
	},

	setCurrentContact(state, contact)
	{
		if (contact == null)
			state.currentContact = null;
		else
			state.currentContact = { ...contact };
	},

	updateCurrentContact(state, data)
	{
		if (state.currentContact != null)
			state.currentContact = { ...state.currentContact, ...data };
	},
}