
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: '/users/',
	routes: [
		{
			name: 'users-main-redirect',
			path: '/',
			redirect: '/courses',
		}
	],
});

export default router;