<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">

			<template v-if="fresh && loading">
				Loading...
			</template>

			<template v-else-if="error">
				<div class="title">Failed to load conversation</div>

				<div class="alert-danger">
					{{ errorMsg }}
				</div>
			</template>

			<template v-else>
				<div class="title">
					Conversation: 
					<u>{{ conversation.started_by.first_name }} {{ conversation.started_by.last_name }}</u>,
					{{ participantNames }}
				</div>

				<div v-if="numOlder > 0 && messageList.length > 0" class="mb-4">
					<ui-button block :loading="loading" color="primary-bordered" @click="refresh(messageList[0].id)">View Older Messages</ui-button>
				</div>

				<div class="message-list">
					<div v-for="msg in messageList" :key="msg.id" :class="getItemClasses(msg)">
						<div class="item-body">{{ msg.message }}</div>
						<div class="item-info">
							Sent by {{ msg.from_user.first_name }}
							on {{ msg.sent_on | dateFormat('h:mma [on] MMM Do, YYYY') }}
						</div>
					</div>
				</div>

			</template>

			<ui-form v-if="!error" ref="form">
				<ui-panel>
					<ui-panel-body>
						<ui-text-box-field 
							label="Message"
							:disabled="sending"
							:rules="rules.message"
							v-model="newMessage"
						/>
					</ui-panel-body>

					<ui-panel-footer>
						<ui-button type="submit" color="primary" :loading="sending" @click="submit">Send</ui-button>
						<ui-button type="button" color="text" :disabled="sending" to="/messages">Back</ui-button>
					</ui-panel-footer>
				</ui-panel>
			</ui-form>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import filters from 'igolf/shared/filters';

export default {
	mixins: [filters],
	props: ['id'],

	data()
	{
		return {
			fresh: true,
			loading: true,
			sending: false,
			error: false,
			errorMsg: null,
			newMessage: '',
			numOlder: 0,

			rules: {
				message: [ v => (v != null && v.trim() != '') || 'Please enter a message' ],
			}
		}
	},

	computed: {
		...mapGetters('messages', ['messageList']),
		...mapState({
			conversation: state => state.messages.currentConversation,
			currentUser: state => state.shell.currentUser,
			otherUser: state => state.messages.conversation.otherUser,
		}),

		participantNames()
		{
			var names = this.conversation.participants.map(p => `${p.first_name} ${p.last_name}`);
			return names.join(', ');
		}
	},

	methods: {
		...mapActions('messages', ['fetchMessageList', 'fetchConversation']),

		async refresh(beforeId)
		{
			this.loading = true;
			this.error = false;

			try
			{
				var result, appending = false, params = {};

				// if first-load then load conversation
				if (this.fresh)
					await this.fetchConversation(this.id);

				// if loading older messages
				if (beforeId)
				{
					params.before_id = beforeId;
					appending = true;
				}

				// load messages
				result = await this.fetchMessageList({
					conversationId: this.id,
					appending,
					params,
				});

				this.numOlder = result.num_older;
				this.fresh = false;
			}
			catch(err)
			{
				console.error(err);
				this.error = true;
				this.errorMsg = err.message;
			}
			finally
			{
				this.loading = false;
			}
		},

		getItemClasses(msg)
		{
			var cls = ['message-list-item'];

			if (msg.from_user_id == this.currentUser.id)
				cls.push('is-sent-msg');
			else
				cls.push('is-recv-msg');

			return cls;
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.sending = true;

			try
			{
				var message = {
					message: this.newMessage,
				};

				await this.$api.post(`/conversations/${this.id}/messages`, { message });
				await this.refresh(true);

				this.newMessage = '';
			}
			finally
			{
				this.sending = false;
			}
		},
	},

	mounted()
	{
		this.refresh();
	}
}
</script>