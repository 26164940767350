
import { mapList } from 'igolf/shared/util';

export default {
	setConversationList(state, list)
	{
		var { convs, ids } = mapList('convs', list);

		state.conversations = convs;
		state.conversationIds = ids;
	},

	setConversation(state, conv)
	{
		if (conv)
			state.currentConversation = { ...conv };
		else
			state.currentConversation = null;
	},

	setMessageList(state, list)
	{
		var { messages, ids } = mapList('messages', list);

		state.messages = messages;
		state.messageIds = ids;
	},

	appendMessages(state, list)
	{
		var { messages, ids } = mapList('messages', list);

		state.messages = { ...state.messages, ...messages };
		state.messageIds = [ ...ids, ...state.messageIds ];
	}

}