
export default { 
	coursesByUrl: state => {
		var map = {};
		
		for(var c of state.courses)
			map[c.url_slug] = c;

		return map;
	},

	cartList: state => state.cartIds.map(id => state.carts[id]),
}