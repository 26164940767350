<template>
	<div :class="fieldClass">
		<div v-if="prependIcon" class="field-icon field-icon-prepend">
			<button tabindex="-1" type="button" v-if="$listeners['prepend-icon-click']" @click.stop="$listeners['prepend-icon-click']"><i :class="prependIcon"/></button>
			<i v-else :class="prependIcon"></i>
		</div>
		<div class="field-widget">
			<div v-if="!hideLabel" class="field-label">{{ label }}</div>
			
			<slot></slot>

			<div v-if="error" class="field-error">
				{{ error }}
			</div>

			<div v-else-if="$slots.hint || hint">
				<slot name="hint">
					<div class="field-hint">{{ hint }}</div>
				</slot>
			</div>
		</div>
		<div v-if="appendIcon" class="field-icon field-icon-append">
			<button tabindex="-1" type="button" v-if="$listeners['append-icon-click']"><i :class="appendIcon"/></button>
			<i v-else :class="appendIcon"></i>
		</div>
	</div>
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	//inject: ['$addToValidation', '$removeFromValidation'],

	inject: {
		'$addToValidation': { default: () => {} },
		'$removeFromValidation': { default: () => {} },
	},

	props: {
		focused: false,
	},

	data()
	{
		return { 
			error: null,
		}
	},

	computed: {
		fieldClass()
		{
			var classes = ['form-field'];

			if (this.disabled)
				classes.push('is-disabled');

			if (this.error)
				classes.push('has-error');

			if (this.focused)
				classes.push('has-focus');

			if (this.$slots.popout)
				classes.push('has-popout');

			if (this.showPopout)
				classes.push('is-popout-showing');

			if (this.hideLabel)
				classes.push('no-label');

			return classes;
		},
	},

	methods: {
		validateField()
		{
			// go through each rule, if any rule function returns a string then use that as the error message and bail out
			this.error = null;
			for(var rule of this.rules)
			{
				// execute rule logic
				var result = rule.call(this, this.validateValue);
				if (typeof(result) == 'string')
				{
					this.error = result;
					console.log('validation failed: ' + result);
					console.log(this);
					return false;
				}
			}

			// if no rules failed then return true
			return true;
		}
	},

	mounted()
	{
		// only register control for validation if there are rules defined
		if (this.$addToValidation)
			if (this.rules && this.rules instanceof Array && this.rules.length > 0)
				this.$addToValidation(this);
	},

	beforeDestroy()
	{
		if (this.$removeFromValidation)
			this.$removeFromValidation(this);
	}

}
</script>