<template>
	<div class="number-stepper">
		<button type="button" tabindex="-1" class="stepper-btn" aria-label="Increase value" @click.prevent="stepValue(1)"><i class="fas fa-caret-up"></i></button>

		<div class="form-field">
			<input 
				type="text"
				class="field-control"
				:value="value"
				@blur="forceNumber($event.target.value)"
				@keypress="forceNumberKeys($event)"
				@keyup="handleArrowKeys($event)"
				@input="updateValue($event.target.value)">
		</div>
		
		<button type="button" tabindex="-1" class="stepper-btn" aria-label="Decrease value" @click.prevent="stepValue(-1)"><i class="fas fa-caret-down"></i></button>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: [Number, String], required: true },
		step: { type: Number, default: 1 },
	},

	methods: {
		updateValue(value)
		{
			this.$emit('input', value);
		},

		stepValue(dir)
		{
			var val = parseFloat(this.value);
			this.updateValue(val + (this.step * dir));
		},

		forceNumberKeys(event)
		{
			if (event.which > 31 && (event.which < 48 || event.which > 57))
				return false;
			else
				return true;
		},

		handleArrowKeys(event)
		{
			// increase on up key pressed
			if (event.which == 38)
				this.stepValue(1);
			// decrease on down key pressed
			else if (event.which == 40)
				this.stepValue(-1);
		},

		forceNumber(value)
		{
			if (isNaN(value))
				this.updateValue(0);
		}
	}
}
</script>