<template>
	<div :class="classes">
		<!-- if panel is collapsible show the toggle icon beside the title content -->
		<template v-if="collapsed != null">
			<div class="panel-title-widget">
				<button type="button" class="panel-toggle-icon" aria-label="Toggle Histoory Item" @click.prevent="toggleCollapse">
					<i class="fas fa-chevron-up" />
				</button>
			</div>

			<div class="panel-title-content">
				<slot></slot>
			</div>
		</template>

		<!-- otherwise just display the panel content -->
		<template v-else>
			<slot></slot>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		collapsed: { type: Boolean, default: null },
	},

	computed: {
		classes()
		{
			var classes = 'panel-title';

			if (this.collapsed != null)
			{
				classes += ' has-widget';

				if (this.collapsed)
					classes += ' is-collapsed';
			}

			return classes;
		}
	},

	methods: {
		toggleCollapse()
		{
			this.$emit('update:collapsed', !this.collapsed);
		}
	}
}
</script>