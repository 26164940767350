
import Vue from 'vue';
import moment from 'moment';

import { mapList } from 'igolf/shared/util';

export default {
	setTeetimesLoading(state, flag)
	{
		state.teetimesLoading = flag;
	},

	// basic teesheet detail mutations
	setCurrentCourse(state, course)
	{
		state.course = { ...course };
	},
	// set the course's current config values
	setCourseConfig(state, config)
	{
		state.courseConfig = { ...config };
	},
	setCurrentDate(state, date)
	{
		state.date = date;
	},

	changeCurrentDay(state, dir)
	{
		var date = moment(state.date, 'YYYY-MM-DD');
		state.date = date.add(dir, 'days').format('YYYY-MM-DD');
	},

	setTeesheetDetails(state, data)
	{
		state.header = { ...data.header };
		state.userLotteriesEntered = { ...data.lotteries_entered };
		state.userLotteryGroups = { ...data.lottery_groups };
		state.loaded = true;
	},

	// teesheet/teetimes mutations
	setTeetimes(state, data)
	{
		var segments = [];
		var notes = {};
		var teetimes = { 
			times: [], 
			rows: {},
			perRow: data.players_per_row,
		};

		for(var current of data.segments)
		{
			var segment = {
				block: current.block,
				blockRange: current.block_range,
				teetimes: [],
			};

			//segment.teetimes = current.teetimes.map(tt => {
			for(var tt of current.teetimes)
			{
				// generate id for row
				var id = `${tt.time}_${tt.hole}`;

				// add squeezes
				/*if (data.squeezed[tt.time])
				{
					for(let sq of data.squeezed[tt.time])
					{
						// generate row id for squeezed group
						let sqid = `sq${sq.squeeze_id}_${tt.time}_${tt.hole}`;

						if (!teetimes.rows[sqid])
						{
							teetimes.rows[sqid] = { ...tt, players: [], id: sqid, squeezed: true, squeezeId: sq.squeeze_id };
							teetimes.times.push(sqid);
							segment.teetimes.push(sqid);
						}

						teetimes.rows[sqid].players.push(sq);
					}
				}*/

				// map notes
				for(let n of tt.notes)
					notes[n.id] = { ...n };

				teetimes.rows[id] = { ...tt, id };
				teetimes.times.push(id);

				segment.teetimes.push(id);
				//return id;
			};

			segments.push(segment);
		}

		/*
		teetimes.times = data.teetimes.map(tt => {
			teetimes.rows[tt.time] = { ...tt };
			return tt.time;
		});
		*/
		
		state.courseParams = { ...data.course_params };
		state.teesheetNotes = notes;
		state.segments = segments;
		state.teetimes = teetimes;
		state.unassignedEntries = data.unassigned;
		state.schedule = JSON.parse(JSON.stringify(data.schedule));	
	},

	// set teetime lock
	setTeetimeLock(state, lock)
	{
		state.teetimeLock = { ...lock };
	},

	// update reservation details
	updateReservationDetails(state, info)
	{
		state.reservation = { ...state.reservation, ...info };
	},

	// replace reservation details
	setReservationDetails(state, details)
	{
		state.reservation = { ...details };
	},

	// set reservation block
	setReservationBlock(state, block)
	{
		if (block == null)
			state.reservationBlock = null;
		else
			state.reservationBlock = JSON.parse(JSON.stringify(block));
	},

	// add booking player
	addTeetimePlayer(state, player)
	{
		// raise an error if the player is already added
		//if (state.teetimePlayers.ids.indexOf(player.id) >= 0)
			//throw new Error('This player has already been added to the reservation!');

		state.teetimePlayers.ids.push(player.id);
		Vue.set(state.teetimePlayers.players, player.id, { ...player });
	},

	// add list of players
	addTeetimePlayerList(state, list)
	{
		console.log(list);
		for(var player of list)
		{
			// remove player from state
			state.teetimePlayers.ids.push(player.id);
			Vue.set(state.teetimePlayers.players, player.id, { ...player });
		}
	},
	
	// update booking player
	updateTeetimePlayer(state, player)
	{
		Vue.set(state.teetimePlayers.players, player.id, { ...player });
	},

	// remove booking player
	removeTeetimePlayer(state, id)
	{
		var pos = state.teetimePlayers.ids.indexOf(id);

		if (pos >= 0)
		{
			var tt = state.teetimePlayers.players[id];

			// add teetime id to removed if valid
			if (tt.teetime_id && state.removedTeetimes.indexOf(tt.teetime_id) == -1)
				state.removedTeetimes.push(tt.teetime_id)

			// remove from state
			state.teetimePlayers.ids.splice(pos, 1);
			Vue.delete(state.teetimePlayers.players, id);
		}
	},

	// add booking fill
	addTeetimeFill(state, fill)
	{
		state.teetimeFills.ids.push(fill.id);
		Vue.set(state.teetimeFills.fills, fill.id, { ...fill });
	},

	// add list of fills
	addTeetimeFillList(state, list)
	{
		for(var fill of list)
		{
			state.teetimeFills.ids.push(fill.id);
			Vue.set(state.teetimeFills.fills, fill.id, { ...fill });
		}
	},
	
	// update booking fill
	updateTeetimeFill(state, fill)
	{
		Vue.set(state.teetimeFills.fills, fill.id, { ...fill });
	},

	// remove booking fill
	removeTeetimeFill(state, id)
	{
		var pos = state.teetimeFills.ids.indexOf(id);

		if (pos >= 0)
		{
			var tt = state.teetimeFills.fills[id];

			// add teetime id to removed if valid
			if (tt.teetime_id && state.removedTeetimes.indexOf(tt.teetime_id) == -1)
				state.removedTeetimes.push(tt.teetime_id)

			// remove fill from state
			state.teetimeFills.ids.splice(pos, 1);
			Vue.delete(state.teetimeFills.fills, id);
		}
	},
	
	// remove all players from reservation
	clearReservationPlayers(state)
	{
		state.removedTeetimes = [];
		
		state.teetimePlayers = {
			ids: [],
			players: {},
		};
	
		state.teetimeFills = {
			ids: [],
			fills: {},
		};
	},

	setReservationBlock(state, block)
	{
		if (block == null)
			state.reservationBlock = null;
		else
			state.reservationBlock = JSON.parse(JSON.stringify(block));
	},

	// toggle entries loading flag
	setLotteryEntriesLoading(state, flag)
	{
		state.entriesLoading = flag;
	},

	// set lottery entry list
	setLotteryEntries(state, params)
	{
		var lotteryEntries = { 
			ids: [], 
			entries: {},
			block: params.block || {},
		};

		lotteryEntries.ids = params.entries.map(e => {
			lotteryEntries.entries[e.id] = { ...e };
			return e.id;
		});

		state.lotteryEntries = lotteryEntries;
	},

	clearLotteryEntries(state)
	{
		state.lotteryEntries = {
			ids: [],
			entries: {},
			block: {},
		};
	},

	showLotteryEntries(state, flag)
	{
		state.viewingLotteryEntries = flag;
	},

	addPublicPlayerGuest(state, item)
	{
		var id = state.publicPlayerGuests.nextId;
		var item = { id, ...item };

		Vue.set(state.publicPlayerGuests, 'nextId', id+1);
		Vue.set(state.publicPlayerGuests.guests, id, item);

		state.publicPlayerGuests.ids.push(id);
	},

	removePublicPlayerGuest(state, id)
	{
		var pos = state.publicPlayerGuests.guests.indexOf(id);

		Vue.delete(state.publicPlayerGuests.guests, id);

		if (pos >= 0)
			state.publicPlayerGuests.guests.splice(pos, 1);
	},

	updatePublicPlayerGuest(state, item)
	{
		if (state.publicPlayerGuests.guests[item.id])
		{
			var org = state.publicPlayerGuests.guests[item.id];
			Vue.set(state.publicPlayerGuests.guests, item.id, { ...org, ...item });
		}
	},

	setPublicPlayerGuests(state, guestList)
	{
		var ids = [], guests = {}, nextId = 1;

		for(var guest of guestList)
		{
			let id = nextId++;
			ids.push(id);
			guests[id] = { id, ...guest };
		}

		state.publicPlayerGuests = { nextId, guests, ids };
	}
}