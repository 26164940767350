import _ from 'lodash/string';
import moment from 'moment';
import { sprintf } from 'sprintf-js';

//import util from './util';

export default {
	filters: {
		currency(value)
		{
			var formatter = new Intl.NumberFormat('en-US', { 
				style: 'currency',
				currency: 'USD',
				minimumFractionDigits: 2
			});
			var num = parseFloat(value) || 0;
			return formatter.format(num);
		},

		dateFormat(value, format)
		{
			if (typeof(value) == 'string')
				return moment(value).format(format);
			else if (typeof(value) == 'number')
				return moment.unix(value).format(format);
			else if (typeof(value) == 'object' && typeof(value.format) == 'function')
				return value.format(format);
			else
				return `!INV: ${value}`;
		},

		to12Hour(value)
		{
			return moment(value, 'HH:mm').format('h:mma');
		},

		numberFormat(value, decimals)
		{
			var dec = parseInt(decimals, 10) || 2;
			return sprintf(`%0.0${dec}f`, value);
			//return parseFloat(value).toFixed(decimals);
		},

		round(value)
		{
			return Math.round(value);
		},

		/*nthNumber(num)
		{
			return util.nthNumber(num);
		},*/

		capitalize(val)
		{
			return _.capitalize(val);
		},

		titleCase(val)
		{
			return _.startCase(val);
		},

		truncate(val, length)
		{
			return val.length < length ? val : val.substr(0, length) + '...';
		}
	},

}