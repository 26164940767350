<template>
	<ul class="tabs">
		<li v-for="tab in tabs" :key="tab.value">
			<button type="button" class="tab-btn" :class="{ 'is-active': value == tab.value }" @click.stop="$emit('input', tab.value)">
				<span class="tab-btn-content">{{ tab.title }}</span>
			</button>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		tabs: { type: Array, required: true },
		value: { type: String, default: '' },
	}
}
</script>