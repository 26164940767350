<script>
import clickHandlers from 'igolf/ux/mixins/click-handler';
import colors from 'igolf/shared/colors';

export default {
	mixins: [clickHandlers],

	props:{ 
		icon: { type: String, required: true },
		color: { type: String },
	},

	render(h)
	{
		var tag = 'span';
		var data = {
			class: 'ui-icon',
		};

		if (this.color)
			data.style = { color: colors[this.color] || this.color };

		if (this.isClickable())
		{
			tag = 'a';
			data.class += ' is-button';
			data.attr = { href: '#', role: 'button' };
			data.on = { click: event => this.handleClick(event) };
		}

		return h(tag, data, [
			h('i', { class: this.icon })
		]);
	}
}
</script>
