import Vue from 'vue';

// breakpoint plugin that lets Vue components check them like this:
// this.$breakpoints.smAndUp or this.$breakpoints.smAndDown

// create media breakpoints
var breakpoints = {

	smAndUp: window.matchMedia('screen and (min-width: 576px)'),
	mdAndUp: window.matchMedia('screen and (min-width: 768px)'),
	lgAndUp: window.matchMedia('screen and (min-width: 992px)'),
	xlAndUp: window.matchMedia('screen and (min-width: 1200px)'),

	//xsAndDown: window.matchMedia('screen and (max-width: 575px)'),
	smAndDown: window.matchMedia('screen and (max-width: 575.999px)'),
	mdAndDown: window.matchMedia('screen and (max-width: 767.999px)'),
	lgAndDown: window.matchMedia('screen and (max-width: 991.999px)'),
	xlAndDown: window.matchMedia('screen and (max-width: 1199.999px)'),

	//mobile: window.matchMedia('screen and (max-width: 800px), screen and (max-width: 1024px) and (orientation: landscape)'),
	//large: window.matchMedia('screen and (min-width: 801px), screen and (min-width: 1025px) and (orientation: landscape)'),
};

var breakpointStatus = {};

for(let name in breakpoints) 
{
	// configure event handlers for each breakpoint to update breakpoint status whenever they change
	breakpoints[name].onchange = e => Vue.set(breakpointStatus, name, e.matches);
	
	// initialize the current status of this breakpoint
	breakpointStatus[name] = breakpoints[name].matches;
}

// make the breakpoint status object observable so changes are reactive with Vue
breakpointStatus = Vue.observable(breakpointStatus);

// plugin to expose breakpoints to Vue components
const Breakpoints = {
	install(Vue, options)
	{
		Vue.mixin({
			computed: {
				$breakpoints()
				{
					return breakpointStatus;
				}
			}
		});
	}
};

// install plugin
Vue.use(Breakpoints);