<template>
	<div class="ui-avatar-base" :style="styles">
		<img :src="src"/>
	</div>
</template>

<script>
export default {
	props: {
		src: { type: String },
		size: { type: Number },
	},

	computed: {
		styles()
		{
			var styles = {};

			styles.width = `${this.size}px`;
			styles.height = `${this.size}px`;

			return styles;
		}
	}
}
</script>

<style scoped>
.ui-avatar-base
{
	border-radius: 9999px;
	display: block;
	overflow: hidden;
	position: relative;
}

.ui-avatar-base img
{
	border: none;
	display: block;
	height: 100%;
	object-fit: cover;
	object-position: center;
	position: relative;
	width: 100%;
}
</style>