
import { createApi } from 'igolf/shared/api';

const api = createApi('/users/api');

export const apiPlugin = {
	install(Vue, options)
	{
		Vue.$api = createApi('/users/api');
	}
}

export default api;