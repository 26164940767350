<template>
	<div class="modal" :class="{ 'is-visible': show }">
		<div class="modal-content" :class="{ 'is-full-width': fullWidth }" :style="{ 'max-width': fullWidth ? 'none' : maxWidth }">
			<div ref="content">
				<slot></slot>
			</div>
			<a href="#" class="modal-close-btn" v-if="showClose" @click.prevent="dismiss" aria-label="Close">
				<i class="fas fa-times"></i>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		showClose: { type: Boolean, default: true },
		show: { type: Boolean, default: false },
		fullWidth: { type: Boolean, default: false },
		dismissOutsideClick: { type: Boolean, default: true },
		maxWidth: { type: [String, Number], default: '1070px' },
	},

	methods: {
		dismiss()
		{
			this.$emit('update:show', false);
		},
	},

	mounted()
	{
		// add outside click handler
		this.onOutsideClick = event => {
			if (!this.$refs.content.contains(event.target))
			{
				this.dismiss();
				event.preventDefault();
				event.stopPropagation();
				return false;
			}
		};

		if (this.dismissOutsideClick)
			this.$el.addEventListener('mousedown touchstart', this.onOutsideClick);
	},

	beforeDestroy()
	{
		if (this.dismissOutsideClick)
			this.$el.removeEventListener('mousedown touchstart', this.onOutsideClick);
	}
}
</script>
