<template>
	<ul :class="mainClasses">
		<li v-for="(item, index) in value" :key="item[itemValue]">
			<div class="ui-chip">
				<slot name="item" v-bind:item="item">
					<button v-if="!hideRemoveButtons" type="button" class="ui-chip-remove" :aria-label="`Remove ${item[itemText]}`" @click="removeItem(index)">
						<i class="fas fa-times"/>
					</button>
					<span class="ui-chip-label">{{ item[itemText] }}</span>
				</slot>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		value: { type: Array, default: () => ([]) },
		itemText: { type: String, default: 'text' },
		itemValue: { type: String, default: 'value' },
		hideRemoveButtons: { type: Boolean, default: false },
	},

	computed: {
		mainClasses()
		{
			if (this.hideRemoveButtons)
				return 'ui-chips';
			else
				return 'ui-chips show-remove-buttons';
		}
	},

	methods: {
		removeItem(index)
		{
			if (index >= 0 && index < this.value.length)
			{
				var items = [...this.value];
				items.splice(index, 1);
				this.$emit('input', items);
			}
		}
	}
}
</script>