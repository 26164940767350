
import moment from 'moment';

export default {
	setCartList(state, list)
	{
		var carts = {};

		state.cartIds = list.map(c => {
			carts[c.id] = c;
			return c.id;
		});
		state.carts = carts;
	},

	setAccountInfo(state, info)
	{
		state.accountInfo = { ...info };
	},

	setCurrentUser(state, user)
	{
		state.currentUser = user ? { ...user } : null;
	},

	updateCurrentUser(state, data)
	{
		if (state.currentUser)
			state.currentUser = { ...state.currentUser, ...data };
	},

	setUserClass(state, cls)
	{
		state.userClass = cls ? { ...cls } : null;
	},

	setCourses(state, list)
	{
		var courses, coursesBySlug = {}, coursesById = {};

		// extract courses
		courses = list.map(c => JSON.parse(JSON.stringify(c)));

		// map courses by slug
		for(var c of courses)
		{
			coursesById[c.id] = c;
			coursesBySlug[c.url_slug] = c;
		}

		state.courses = courses;
		state.coursesBySlug = coursesBySlug;
		state.coursesById = coursesById;
	},

	showMenu(state, flag)
	{
		state.menuVisible = flag ? true : false;
	},

	showDatePicker(state, flag)
	{
		state.datePickerVisible = flag ? true : false;
	},

	setCurrentDate(state, date)
	{
		state.currentDate = date;
	},

	setInterfaceNotes(state, notes)
	{
		state.interfaceNotes = JSON.parse(JSON.stringify(notes));
	}
}