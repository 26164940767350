<template>
	<ui-form-field v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value">
		<textarea
			:class="classes"
			:value="value"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:style="{ height }"
			@input="$emit('input', $event.target.value)"
			@blur="setFocus(false)"
			@focus="setFocus(true)">
		</textarea>
	</ui-form-field>
</template>

<script>
import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps],
	inject: ['$addToValidation'],

	props: { 
		height: { type: String, default: '150px' },
		value: { type: String, default: '' },
		placeholder: { type: String, default: '' },
		readonly: { type: Boolean, default: false },
		monospace: { type: Boolean, default: false },
	},

	computed: {
		classes()
		{
			var res = ['field-control'];

			if (this.monospace)
				res.push('monospace');

			return res;
		}
	}
}
</script>