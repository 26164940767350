
export default {
	mounted()
	{
		this.onOutsideClick = (event) => {
			var current = event.target;

			do
			{
				if (current == this.$el)
					return;
				else
					current = current.parentNode;
			} while(current);

			this.$emit('outside-click');
		}

		document.querySelector('body').addEventListener('click', this.onOutsideClick);
		document.querySelector('body').addEventListener('touchend', this.onOutsideClick);
	},

	beforeDestroy()
	{
		document.removeEventListener('click', this.onOutsideClick);
		document.removeEventListener('touchend', this.onOutsideClick);
	}
}