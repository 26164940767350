<template>
	<ui-menu :show.sync="visible" :close-on-content-click="false" position="bottom-left">
		<template v-slot:activator>
			<ui-form-field ref="field" v-bind="formFieldAttrs" :focused="hasFocus" :validate-value="value">
				<input 
					ref="field"
					type="text"
					class="field-control"
					:class="{ 'is-compact': compact }"
					:value="value"
					@input="$emit('input', $event.target.value)"
					@keyup="onFieldKeyPress($event)"
					@focus="visible=true"
					@blur="visible=false"
				/>
			</ui-form-field>
		</template>

		<ui-panel class="is-floating auto-complete-popout">
			<ui-progress-bar v-if="loading" indeterminate />
			<ui-list v-if="currentOptions.length > 0">
				<ui-list-item-row v-for="item in currentOptions" :key="item[keyField]" :class="{ 'is-selected': item[keyField] == selectedKey }" @click="selectItem(item)">
					<ui-list-item-content>
						<ui-list-item-title>{{ item[valueField] }}</ui-list-item-title>
					</ui-list-item-content>
				</ui-list-item-row>
			</ui-list>

			<ui-panel-body v-else>
				<i>No matches found</i>
			</ui-panel-body>
		</ui-panel>
	</ui-menu>
</template>

<script>
import outsideClick from 'igolf/shared/outside-click';

import formFieldProps from '../form-field';

export default {
	mixins: [formFieldProps, outsideClick],
	inject: ['$addToValidation'],

	props: { 
		value: { type: String, default: '' },
		keyField: { type: String, default: 'id' },
		valueField: { type: String, default: 'value' },
		fetchOptions: { type: Function, default: null },
		options: { type: Array, default: () => ([]), },
	},

	data()
	{
		return {
			loading: false,
			visible: false,
			selectedKey: null,
			currentOptions: [],
		}
	},

	watch: {
		value(newVal, oldVal)
		{
			if (newVal != oldVal)
				this.updateOptions();
		}
	},

	methods: {
		async updateOptions()
		{
			// if static options were specified filter them against the current value
			if (this.options.length > 0)
			{
				var val = this.value.toLowerCase().trim();
				this.currentOptions = this.options.filter(x => x[this.valueField].toLowerCase().trim().indexOf(val) >= 0);
			}
			// otherwise use fetcher
			else if (this.fetchOptions && typeof(this.fetchOptions) == 'function')
			{
				// if (this.loading) 
				// 	return;
				
				this.loading = true;
				try
				{
					var result = await this.fetchOptions(this.value);
					this.currentOptions = [ ...result ];
				}
				finally
				{
					this.loading = false;
				}
			}
		},

		setVisible(flag)
		{
			this.visible = flag;
			// if (!this.visible)
			// 	this.setFocus(false);
		},

		selectItem(item)
		{
			this.$emit('input', item[this.valueField]);
			this.selectedKey = null;
			this.visible = false;
			//this.$refs.field.blur();
		},

		onFieldKeyPress(event)
		{
			var item, pos;

			// do nothing if loading
			if (this.loading)
				return;

			// do nothing else if its not a key we're interested in
			if (![38, 40, 13].includes(event.which))
				return;

			// look for selected item
			item = this.currentOptions.find(i => i[this.keyField] == this.selectedKey);

			// if found then get the position of the item in the option list
			if (item)
			{
				pos = this.currentOptions.indexOf(item);
			}
			// otherwise just highlight the first item and exit
			else
			{
				this.selectedKey = this.currentOptions[0][this.keyField];
				return;
			}

			// user pushed up
			if (event.which == 38)
			{
				pos--;
			}
			// user pushed down
			else if (event.which == 40)
			{
				pos++;
			}
			// user pushed enter, trigger the selection and exit now
			else if (event.which == 13 && this.selectedKey != null)
			{
				this.selectItem(item);
				return;
			}

			if (pos < 0)
				pos = 0;
			else if (pos >= this.currentOptions.length)
				pos = this.currentOptions.length-1;

			this.selectedKey = this.currentOptions[pos][this.keyField];
		},
	},

	mounted()
	{
		this.$on('outside-click', () => this.visible = false);
		this.currentOptions = [...this.options];
	}

}
</script>
