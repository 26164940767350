<template>
	<div :class="classes">
		<slot name="activator" v-bind:on="onHandler"></slot>

		<div class="menu-popout" @click="onContentClick">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {

	props: {
		show: { type: Boolean, default: false },
		closeOnContentClick: { type: Boolean, default: true },
		position: { type: String, default: 'top-left' },
	},

	computed: {
		visible: {
			get() {
				return this.show;
			},

			set(val) {
				this.$emit('update:show', val);
			}
		},

		classes()
		{
			var cls = ['menu'];

			if (this.visible)
				cls.push('is-open');

			cls.push(`popout-${this.position}`);

			return cls;
		}
	},

	data() {

		return {
			onHandler: {
				click: event => this.visible = true,
				touchstart: event => this.visible = true,
				keyup: event => {
					if (event.which == 13)
						this.visible = true;
					else if (event.which == 27)
						this.visible = false;
				}
			}
		}
	},

	methods: {
		onContentClick()
		{
			if (this.closeOnContentClick)
				this.visible = false;
		}
	},

}
</script>