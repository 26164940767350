
export default {
	conversations: {},
	conversationIds: [],

	currentConversation: null,
	
	messages: {},
	messageIds: [],

}