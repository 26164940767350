<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">
			<div class="title">Edit Contact</div>

			<ui-panel>
				<ui-panel-body v-if="loading">
					Loading...
				</ui-panel-body>

				<ui-panel-body v-else>

					<ui-form ref="form">

						<div v-if="loading">
							Loading...
						</div>

						<div v-else class="row">
							<div class="col-xs-12">
								<ui-text-box-field
									label="Name"
									:prepend-icon="fieldIcons.name"
									:value="contact.name"
									:rules="rules.name"
									@prepend-icon-click="toggleFieldVisibility('name')"
									@input="updateField('name', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Email"
									:prepend-icon="fieldIcons.email"
									:value="contact.email"
									:rules="rules.email"
									@prepend-icon-click="toggleFieldVisibility('email')"
									@input="updateField('email', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Phone"
									:prepend-icon="fieldIcons.phone"
									:value="contact.phone"
									@prepend-icon-click="toggleFieldVisibility('phone')"
									@input="updateField('phone', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Fax"
									:prepend-icon="fieldIcons.fax"
									:value="contact.fax"
									@prepend-icon-click="toggleFieldVisibility('fax')"
									@input="updateField('fax', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-xs-12">
								<ui-text-box-field
									label="Address"
									:prepend-icon="fieldIcons.address"
									:value="contact.address"
									@prepend-icon-click="toggleFieldVisibility('address')"
									@input="updateField('address', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="City"
									:prepend-icon="fieldIcons.city"
									:value="contact.city"
									@prepend-icon-click="toggleFieldVisibility('city')"
									@input="updateField('city', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Province/State"
									:prepend-icon="fieldIcons.region"
									:value="contact.region"
									@prepend-icon-click="toggleFieldVisibility('region')"
									@input="updateField('region', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Country"
									:prepend-icon="fieldIcons.country"
									:value="contact.country"
									@prepend-icon-click="toggleFieldVisibility('country')"
									@input="updateField('country', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-md-6">
								<ui-text-box-field
									label="Postal Code"
									:prepend-icon="fieldIcons.postal_code"
									:value="contact.postal_code"
									@prepend-icon-click="toggleFieldVisibility('postal_code')"
									@input="updateField('postal_code', $event)">
								</ui-text-box-field>
							</div>

							<div class="col-xs-12">
								<ui-checkbox
									label="Is Primary"
									:data="contact.is_primary"
									@input="updateField('is_primary', $event)">
								</ui-checkbox>
							</div>
						</div>

					</ui-form>
							
				</ui-panel-body>

				<ui-panel-footer v-if="!loading">
					<ui-button color="primary" :loading="saving" @click="save">Save</ui-button>
					<ui-button color="text" :disabled="saving" to="/profile?tab=contacts">Cancel</ui-button>
				</ui-panel-footer>
			</ui-panel>
		</div>
	</div>	
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import validate from 'igolf/shared/validators';
import alerts from 'igolf/shared/alerts/';

const showFieldOptions = ['email', 'phone', 'fax', 'address', 'city', 'postal_code', 'region', 'country'];

///adsfasdf/

export default {
	props: {
		id: { type: [String, Number], required: true },
	},

	data()
	{
		return {
			loading: true,
			saving: false,
			rules: {
				name: [ v => !!v || 'Please enter a name for this contact' ],
				email: [
					v => !!v || 'Email address is required',
					v => validate.email(v) || 'Invalid email address',
				]
			}
		}
	},

	computed: {
		...mapState({
			contact: state => state.profile.currentContact,
			userClass: state => state.shell.userClass,
		}),

		fieldIcons()
		{
			var result = {};

			for(var f of showFieldOptions)
				result[f] = this.contact.visible_fields.indexOf(f) >= 0 ? 'far fa-eye' : 'far fa-eye-slash';

			return result;
		}
	},

	methods: {
		updateField(field, val)
		{
			this.$store.commit('profile/updateCurrentContact', { [field]: val });
		},

		toggleFieldVisibility(field)
		{
			var fields = this.contact.visible_fields.slice();
			var pos = fields.indexOf(field);

			if (pos >= 0)
				fields.splice(pos, 1);
			else
				fields.push(field);

			this.updateField('visible_fields', fields);
		},

		async loadContact()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('profile/fetchContact', { id: this.id });
			}
			finally
			{
				this.loading = false;
			}
		},

		async save()
		{
			if (!this.$refs.form.validate())
				return;

			this.saving = true;

			try
			{
				await this.$store.dispatch('profile/saveCurrentContact');
				await alerts.show('Contact saved successfully', 'Success', 'success');

				this.$router.push('/profile?tab=contacts');
			}
			finally
			{
				this.saving = false;
			}
		},
	},

	mounted()
	{
		if (this.id == 'new')
		{
			this.$store.commit('profile/setCurrentContact', {
				id: 0,
				name: '',
				email: '',
				phone: '',
				fax: '',
				address: '',
				city: '',
				region: '',
				country: '',
				postal_code: '',
				is_primary: false,
				visible_fields: this.userClass.visible_fields_default.slice(),				
			});

			this.loading = false;
		}
		else
		{
			this.loadContact();	
		}
	}
}
</script>