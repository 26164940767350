<template>
	<button :type="type" ref="btn" :class="classes" :disabled="disabled || loading" @click.prevent="handleClick">
		<span class="btn-content">
			<template v-if="icon">
				<i :class="icon"/>&nbsp;
			</template>
			
			<slot v-if="!loading">
				{{ label }}
			</slot>

			<slot v-else name="loading">
				Please wait...
			</slot>
		</span>
	</button>
</template>

<script>
import clickHandler from 'igolf/ux/mixins/click-handler';

export default {
	mixins: [clickHandler],

	props: {
		label: { type: String, default: '' },
		color: { type: String, default: 'blue' },
		icon: { type: String, default: null },
		block: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		loading: { type: Boolean, default: false },
		small: { type: Boolean, default: false },
		stacked: { type: Boolean, default: false },
		type: { type: String },
		//to: { type: [String, Object], default: null },
		//href: { type: String, default: null },
		//open: { type: String, default: null },
	},

	computed: {
		classes()
		{
			var base = `btn-${this.color}`;
			var classes = [base];
			
			if (this.loading)
				classes.push('is-loading');

			if (this.icon)
				classes.push('has-icon');

			if (this.block)
				classes.push('is-block');

			if (this.small)
				classes.push('is-small');

			if (this.stacked)
				classes.push('is-stacked');

			return classes;
		}
	},

	methods: {
		focus()
		{
			this.$el.focus();
		},

		/*onClick(event)
		{
			// do nothing if the button is loading
			if (this.loading)
			{
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// navigate to the given url if 'href' is specified
			else if (this.href)
			{
				window.location = this.href;
			}
			// open a new tab if 'open' is specified
			else if (this.open)
			{
				window.open(this.open, '_blank');
			}
			// navigate to the route if 'to' is specified
			else if (this.to && this.$router)
			{
				this.$router.push(this.to)
				event.stopPropagation();
				event.preventDefault();
				return false;
			}
			// otherwise emit a click event
			else
			{
				this.$emit('click');
				return false;
			}
		}*/
	}
}
</script>