<template>
	<div>
		<div class="row" v-for="(item, index) in guestList" :key="item.id">
			<div class="col-md-6">
				<ui-auto-complete 
					:label="`Guest #${index+1}`"
					key-field="id"
					value-field="fill_desc"
					:rules="rules.guestName"
					:fetch-options="$options.fetchUserFills"
					:value="item.name"
					@input="updateGuestField(item.id, 'name', $event)"
				/>
			</div>

			<div class="col-md-6">
				<ui-select-field
					label="Cart"
					empty-text="No Cart"
					:empty-value="0"
					:items="cartOptions"
					:value="item.cart_id"
					@input="updateGuestField(item.id, 'cart_id', $event)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import api from 'igolf/users/api';
import filters from 'igolf/shared/filters';

async function fetchUserFills(search)
{
	var result = await api.get('/player_fill_search', { params: { search } });
	return result.data.fills;
}

export default {
	fetchUserFills,

	data()
	{
		return {
			rules: {
				guestName: [ v => !!v || 'Please enter your guest\'s name' ],
			}
		}
	},

	computed: {
		...mapGetters('shell', ['cartList']),
		...mapState({
			course: state => state.teesheet.course,
			reservation: state => state.teesheet.reservation,
			numGuests: state => state.teesheet.reservation.publicGuests || 0,
			publicGuests: state => state.teesheet.publicPlayerGuests,
		}),

		guestList()
		{
			return this.publicGuests.ids.map(id => this.publicGuests.guests[id]);
		},

		cartOptions()
		{
			return this.cartList.map(c => ({
				text: `${c.abbrev} (${filters.filters.currency(c.price)})`,
				value: c.id,
			}));
		}
	},

	watch: {
		numGuests(newVal, oldVal)
		{
			if (newVal != oldVal)
				this.resetGuestList();
		}
	},

	methods: {
		...mapMutations('teesheet', ['addPublicPlayerGuest', 'removePublicPlayerGuest', 'updatePublicPlayerGuest', 'setPublicPlayerGuests']),

		// reset name list when # of guests changes, preserving whatever was there before
		resetGuestList()
		{
			var newList = [];

			for(var i=0; i<this.numGuests; i++)
				newList.push(i < this.guestList.length ? { ...this.guestList[i] } : { name: '', cart_id: 0 });

			this.setPublicPlayerGuests(newList);
		},

		updateGuestField(id, field, value)
		{
			this.updatePublicPlayerGuest({ id, [field]: value });
		}
	},

	mounted()
	{
		this.resetGuestList();
	}
}
</script>