<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div v-if="loading" class="container">
			Loading...
		</div>

		<div v-else-if="isAllowed" class="container">
			<div class="row justify-space-between">
				<div class="col-md-6">
					<div class="title">Guest List</div>
				</div>

				<div class="col-md-6 text-align-right" :class="{ 'mb-4': $breakpoints.smAndDown }">
					<ui-button :block="$breakpoints.smAndDown" color="primary" class="nowrap" @click="showGuestForm = true">Create New Guest</ui-button>
				</div>
			</div>

			<div class="row">
				<div class="col-md-4">
					<user-search 
						label="Guest Search"
						user-type="guest"
						@selected="addUser"
					/>
				</div>

				<div class="col-md-8">
					<ui-panel>
						<ui-panel-body v-if="guestList.length == 0">
							Your guest list is empty 
						</ui-panel-body>

						<ui-list v-else>
							<ui-list-section>
								<ui-list-item-row v-for="user in guestList" :key="user.id">
									<ui-list-item-content>
										<ui-list-item-title>{{ user.first_name }} {{ user.last_name }}</ui-list-item-title>
									</ui-list-item-content>

									<ui-list-item-icon>
										<ui-icon icon="fas fa-trash" color="red" @click="removeUser(user)"/>
									</ui-list-item-icon>
								</ui-list-item-row>
							</ui-list-section>
						</ui-list>
					</ui-panel>
				</div>
			</div>
		</div>

		<div v-else class="container">
			You are not allowed to create guests
		</div>

		<new-guest-form :show="showGuestForm" @close="guestFormClosed" />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';

import UserSearch from 'igolf/users/shared/UserSearch.vue';

import NewGuestForm from './NewGuestForm.vue';

export default {
	components: { UserSearch, NewGuestForm },

	data()
	{
		return {
			loading: false,
			isAllowed: true,
			showGuestForm: false,
		}
	},

	computed: {
		...mapGetters('guests', ['guestList']),
		...mapState({
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		async refreshGuests()
		{
			this.loading = true;

			try
			{
				var result = await this.$store.dispatch('guests/fetchGuestList');
				this.isAllowed = result.is_allowed;
			}
			finally
			{
				this.loading = false;
			}
		},

		guestFormClosed(refresh)
		{
			this.showGuestForm = false;
			if (refresh)
				this.refreshGuests();
		},

		async addUser(user)
		{
			this.loading = true;

			try
			{
				var params = { guest_id: user.id };
				await this.$api.put('/guests', params);
				await this.refreshGuests();
			}
			finally
			{
				this.loading = false;
			}
		},

		async removeUser(user)
		{
			if (!await alerts.confirm(`Are you sure you want to remove ${user.first_name} ${user.last_name} from your guest list?`, 'Remove Player', 'question'))
				return;

			this.loading = true;

			try
			{
				await this.$api.delete('/guests', { params: { guest_id: user.id } });
				await this.refreshGuests();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshGuests();
	}
}
</script>