<template>
	<ui-modal :show="show" @update:show="$emit('update:show', $event)">
		<ui-panel>
			<ui-panel-title><div class="subtitle">Add Fill</div></ui-panel-title>
			<ui-panel-body>
				<ui-form ref="form">
					<ui-text-box-field
						label="Description"
						:rules="rules.desc"
						v-model="fill.fill_desc">
					</ui-text-box-field>

					<div class="checkbox-list vert">
						<ui-checkbox
							label="Set fill expiry date"
							v-model="doesExpire">
						</ui-checkbox>
					</div>

					<ui-date-picker
						v-if="doesExpire"
						label="Expiry Date"
						:rules="rules.expiry"
						v-model="fill.expires_on">
					</ui-date-picker>
				</ui-form>
			</ui-panel-body>

			<ui-panel-footer>
				<ui-button color="primary" :loading="saving" @click="save">Add Fill</ui-button>
				<ui-button color="text" :disabled="saving" @click="dismiss">Cancel</ui-button>
			</ui-panel-footer>
		</ui-panel>
	</ui-modal>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
	props: { 
		show: { type: Boolean, default: true },
	},

	data()
	{
		return {
			fill: {
				fill_desc: '',
				expires_on: '',
				user_type: 'fill',
			},

			rules: {
				desc: [ v => !!v || 'Please enter a description' ],
				expiry: [ v => !this.doesExpire || !!v || 'Please select an expiry date' ],
			},

			saving: false,
			doesExpire: false,
		}
	},

	methods: {
		...mapMutations('teesheet', ['addTeetimeFill']),

		resetFill()
		{
			this.fill = { fill_desc: '', expires_on: '' };
			this.doesExpire = false;
		},

		async save()
		{
			// validate data
			if (!this.$refs.form.validate())
				return;

			// add fill to database and then to reservation
			this.saving = true;
			try
			{
				// send to server
				var result = await this.$api.post('/users', { user: this.fill });

				// add to reservation
				this.addTeetimeFill({ ...this.fill, id: result.data.user.id });
				this.dismiss();
			}
			finally
			{
				this.saving = false;
			}
		},

		dismiss()
		{
			this.resetFill();
			this.$emit('update:show', false);
		}
	}
}
</script>