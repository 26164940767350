<template>
	<div class="users-main-shell">
		<template v-if="!$route.meta.noMenu">
			<main-menu/>
			<menu-bar/>
		</template>

		<router-view></router-view>

		<!--
		<shell-header banner-size="large" />
		<teetimes/>
		<date-picker-popup :show.sync="datePicker" />
		-->

		<!-- GLOBAL LOADING COVER -->
		<div class="app-loading-cover" :class="{ 'is-visible': loadingCover.active }">
			<div class="loading-content">
				<div class="loading-spinner"></div>
				<div class="loading-text">{{ loadingCover.msg || 'Please wait...' }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import MenuBar from './MenuBar.vue';
import MainMenu from './MainMenu.vue';

export default {
	components: { MenuBar, MainMenu },

	provide()
	{
		return {
			'$setLoadingCover': this.setLoadingCover,
		}
	},

	data() 
	{
		return {
			loadingCover: {
				active: false,
				msg: '',
			}
		}
	},

	methods: {
		setLoadingCover(visible, msg)
		{
			if (visible)
				this.loadingCover = { active: true, msg: msg || 'Please wait...' };
			else
				this.loadingCover = { active: false, msg: '' };
		}
	}
}
</script>
