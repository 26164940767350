
import router from 'igolf/users/router';

import { addRequireUserGuard } from '../shared/require-user';

import BuddyListMain from './comp/BuddyListMain.vue';

router.addRoutes(addRequireUserGuard([
	{
		name: 'users-buddy-list',
		path: '/buddies',
		component: BuddyListMain,
	}
]));