<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">
			<div class="title">Buddy List</div>

			<div class="row">
				<div class="col-md-4">
					<user-search 
						label="Member Search"
						user-type="member"
						@selected="addUser"
					/>
				</div>

				<div class="col-md-8">
					<ui-panel>
						<ui-panel-body v-if="loading">
							Loading...
						</ui-panel-body>

						<ui-panel-body v-else-if="buddyList.length == 0">
							Your buddy list is empty 
						</ui-panel-body>

						<ui-list v-else>
							<ui-list-section>
								<ui-list-item-row v-for="user in buddyList" :key="user.id">
									<ui-list-item-avatar>
										<ui-avatar src="/images/avatar-placeholder.gif" :size="36" />
									</ui-list-item-avatar>

									<ui-list-item-content>
										<ui-list-item-title>{{ user.first_name }} {{ user.last_name }}</ui-list-item-title>
									</ui-list-item-content>

									<ui-list-item-icon>
										<ui-icon icon="fas fa-trash" color="red" @click="removeUser(user)"/>
									</ui-list-item-icon>
								</ui-list-item-row>
							</ui-list-section>
						</ui-list>
					</ui-panel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import alerts from 'igolf/shared/alerts';

import UserSearch from 'igolf/users/shared/UserSearch.vue';

export default {
	components: { UserSearch },

	data()
	{
		return {
			loading: false,
		}
	},

	computed: {
		...mapGetters('buddies', ['buddyList']),
		...mapState({
			currentUser: state => state.shell.currentUser,
		})
	},

	methods: {
		async refreshBuddies()
		{
			this.loading = true;

			try
			{
				await this.$store.dispatch('buddies/fetchBuddyList');
			}
			finally
			{
				this.loading = false;
			}
		},

		async addUser(user)
		{
			if (user.id == this.currentUser.id)
			{
				alerts.show('You cannot add yourself as a buddy', 'Sorry', 'warning');
				return;
			}

			this.loading = true;

			try
			{
				var params = { user_id: user.id };
				await this.$api.post('/buddies', params);
				await this.refreshBuddies();
			}
			finally
			{
				this.loading = false;
			}
		},

		async removeUser(user)
		{
			if (!await alerts.confirm(`Are you sure you want to remove ${user.first_name} ${user.last_name} from your buddy list?`, 'Remove Player', 'question'))
				return;

			this.loading = true;

			try
			{
				await this.$api.delete('/buddies', { params: { user_id: user.id } });
				await this.refreshBuddies();
			}
			finally
			{
				this.loading = false;
			}
		}
	},

	mounted()
	{
		this.refreshBuddies();
	}
}
</script>