<template>
	<div class="menubar" :class="{ 'is-sticky': sticky, 'is-small': small, 'is-frontend': frontend }">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		sticky: { type: Boolean, default: false },
		frontend: { type: Boolean, default: false },
		small: { type: Boolean, default: false,}
	},
}
</script>