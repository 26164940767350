
import Vue from 'vue';

import Button from './comp/Button.vue';
import Checkbox from './comp/Checkbox.vue';
import Radio from './comp/Radio.vue';
import FormField from './comp/FormField.vue';
import TextBoxField from './comp/TextBoxField.vue';
import TextAreaField from './comp/TextAreaField.vue';
import SelectField from './comp/SelectField.vue';
import Form from './comp/Form.vue';
import Notice from './comp/Notice.vue';
import Icon from './comp/Icon.vue';
import ProgressBar from './comp/ProgressBar.vue';
import Calendar from './comp/Calendar.vue';
import DatePicker from './comp/DatePicker.vue';
//import RichTextEditor from './comp/RTE/RichTextEditor.vue';
import Avatar from './comp/Avatar.vue';
import RadioButtonGroup from './comp/RadioButtonGroup.vue';
import TimeField from './comp/TimeField.vue';
import TimePicker from './comp/TimePicker.vue';
import Stepper from './comp/Stepper.vue';
import AutoComplete from './comp/AutoComplete.vue';

//import SortableList from './comp/SortableList.vue';
import DataTable from './comp/DataTable.vue';
import Modal from './comp/Modal.vue';
import Menu from './comp/Menu.vue';
import Tabs from './comp/Tabs.vue';
import Menubar from './comp/Menubar.vue';
import MenubarItem from './comp/MenubarItem.vue';
import Tooltip from './comp/Tooltip.vue';
import Chips from './comp/Chips.vue';

import {
	List,
	ListSection,
	ListHeader,
	ListItemRow,
	ListItemContent,
	ListItemIcon,
	ListItemAvatar,
	ListItemTitle,
	ListItemSubtitle
} from './list-components';

import './panel-components';

function registerComponents(map)
{
	for(var name in map)
	{
		Vue.component(`Ui${name}`, map[name]);
	}
}

registerComponents({
	Button, Checkbox, Radio, FormField, TextBoxField, TextAreaField, SelectField,
	List, ListHeader, ListItemIcon, ListItemAvatar, ListSection, ListItemRow, ListItemContent, ListItemTitle, ListItemSubtitle,
	Modal, Form, Notice, Icon, DataTable, ProgressBar, Calendar, DatePicker, Menu, Tabs,
	Menubar, MenubarItem, Avatar, RadioButtonGroup, TimePicker, TimeField, Stepper, Tooltip, Chips, AutoComplete,
});
