
import Vue from 'vue';
import Vuex from 'vuex';

import shell from './shell/store';
import teesheet from './teesheet/store';
import bookings from './bookings/store';
import buddies from './buddies/store';
import guests from './guests/store';
import profile from './profile/store';
import messages from './messages/store';
//import pop from './pop/store';

Vue.use(Vuex);

const store = new Vuex.Store({
	modules: { shell, teesheet, bookings, buddies, guests, profile, messages }
});

export default store;