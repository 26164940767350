
import api from 'igolf/users/api';

export default {
	async fetchContactList({ commit })
	{
		var result = await api.get('/contacts');
		commit('setContactList', result.data.contacts);
	},

	async fetchContact({ commit }, params)
	{
		var result = await api.get(`/contacts/${params.id}`);
		commit('setCurrentContact', result.data.contact);
	},

	async saveCurrentContact({ state })
	{
		var contact = state.currentContact;

		if (!contact)
			return;

		if (contact.id == 0)
			await api.post('/contacts', { contact });
		else
			await api.put(`/contacts/${contact.id}`, { contact });
	}
}