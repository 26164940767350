<template>
	<div>
		<div class="shell-header-top no-banner"></div>

		<div class="container">
			<div class="title">New Message</div>

			<div class="row">
				<div class="col-md-4">
					<user-search 
						label="Add Participants"
						user-type="member"
						@selected="addParticipant"
					/>
				</div>

				<div class="col-md-8">
					<ui-panel>
						<ui-panel-title>
							<div class="panel-heading">New Message</div>
						</ui-panel-title>

						<ui-panel-body>
							<ui-form ref="form">
								<ui-form-field label="Participants" :rules="rules.participants" :validate-value="participants">
									<span v-if="participants.length == 0">No participants added</span>

									<ui-chips 
										v-else
										v-model="participants"
										item-text="fullname"
										item-value="id"
									/>
								</ui-form-field>

								<ui-text-area-field
									label="Message"
									:rules="rules.message"
									v-model="message"
								/>
							</ui-form>
						</ui-panel-body>

						<ui-panel-footer>
							<ui-button color="primary" :loading="sending" @click="submit">Send</ui-button>
							<ui-button color="text" to="/messages" :disabled="sending">Back</ui-button>
						</ui-panel-footer>
					</ui-panel>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import alerts from 'igolf/shared/alerts';

import UserSearch from 'igolf/users/shared/UserSearch.vue';

export default {
	components: {
		UserSearch,
	},

	data()
	{
		return {
			message: '',
			participants: [],
			sending: false,

			rules: {
				sendTo: [ v => this.sendTo != null || 'Please select a recipient' ],
				message: [ v => !!v || 'Please enter your message' ],
				participants: [ v => v.length > 0 || 'Please select at least one participant' ],
			}
		}
	},

	methods: {
		addParticipant(user)
		{
			var found = this.participants.find(x => x.id == user.id);
			if (!found)
			{
				this.participants.push({ 
					id: user.id,
					fullname: `${user.first_name} ${user.last_name}`,
				});
			}
		},

		async submit()
		{
			if (!this.$refs.form.validate())
				return;

			this.sending = true;

			try
			{
				var conversation = {
					participants: this.participants.map(p => p.id),
				};

				await this.$api.post('/conversations', { conversation, message: this.message });
				await alerts.show('Message sent successfully', 'Success', 'success');
			}
			finally
			{
				this.sending = false;
			}
		}
	}
}
</script>