<template>
	<div class="ui-image-cropper">
		<!-- IF NO IMAGE IS SELECTED -->
		<template v-if="imageSrc == null">
			<div class="no-image-selected">
				<div v-if="reading" class="select-image-widget">
					<div class="select-image-label">Reading image...</div>
				</div>

				<!-- DISPLAY SELECT BUTTON IF NOT LOADING -->
				<div v-else class="select-image-widget">
					<div class="select-image-label">No image selected</div>
					<ui-button color="primary" @click="browseImages">Select Image</ui-button>
				</div>
			</div>
		</template>

		<!-- IMAGE CROPPING INTERFACE -->
		<template v-else-if="imageSrc != null">
			<cropper 
				auto-zoom
				:stencil-component="stencilComponent"
				:src="imageSrc"
				@change="updateInfo"
			/>
		</template>

		<input ref="imageFile" type="file" style="display:none;" accept="images/*;capture=camera" @change="selectImageFile" />
	</div>
</template>

<script>
import 'vue-advanced-cropper/dist/style.css';

import { Cropper } from 'vue-advanced-cropper';

import alerts from 'igolf/shared/alerts';

export default {
	components: { Cropper },

	props: {
		stencil: { type: String, default: null },
	},

	data()
	{
		return {
			reading: false,
			image: null,
			imageSrc: null,
		}
	},

	computed: {
		stencilComponent()
		{
			if (this.stencil == 'circle')
				return 'circle-stencil';
			else
				return 'rectangle-stencil';
		}
	},

	methods: {
		readImage()
		{
			var reader = new FileReader();

			this.reading = true;

			reader.onload = () => {
				this.imageSrc = reader.result;
				this.reading = false;
			};

			reader.readAsDataURL(this.image);
		},

		browseImages()
		{
			this.$refs.imageFile.click();
		},

		selectImageFile(event)
		{
			if (event.target.files.length > 0)
			{
				var file = event.target.files[0];
				
				if (file.type.match(/image\/*/))
				{
					// read file into memory 
					this.image = file;
					this.readImage();
				}
				else
				{
					alerts.show('Invalid file selected', 'Error', 'error');
				}
			}
		},

		updateInfo(info)
		{
			this.$emit('change', {
				...info,
				imageFile: this.image,
			});
		}
	},
}
</script>