<template>
	<ui-modal :show="show" @update:show="$emit('update:show', $event)">
		<ui-panel>
			<ui-panel-title><div class="subtitle">Add Guest</div></ui-panel-title>
			<ui-panel-body>
				<ui-form ref="form">
					<div class="row">
						<div class="col-md-6">
							<ui-text-box-field
								label="First Name"
								:rules="rules.first_name"
								v-model="guest.first_name">
							</ui-text-box-field>
						</div>

						<div class="col-md-6">
							<ui-text-box-field
								label="Last Name"
								:rules="rules.last_name"
								v-model="guest.last_name">
							</ui-text-box-field>
						</div>

						<div class="col-md-6">
							<ui-text-box-field
								label="Email"
								:rules="rules.primary_email"
								v-model="guest.primary_email">
							</ui-text-box-field>
						</div>

						<div class="col-md-6">
							<ui-select-field
								label="Gender"
								v-model="guest.gender"
								:items="genderOptions"
								:rules="rules.gender">
							</ui-select-field>
						</div>
					</div>
				
				</ui-form>

				<p class="text-danger">All fields are mandatory</p>

			</ui-panel-body>

			<ui-panel-footer>
				<ui-button color="primary" :loading="saving" @click="save">Add Guest</ui-button>
				<ui-button color="text" :disabled="saving" @click="dismiss">Cancel</ui-button>
			</ui-panel-footer>
		</ui-panel>
	</ui-modal>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

import validate from 'igolf/shared/validators';

const defaultGuest = {
	user_class_id: 0,
	first_name: '',
	last_name: '',
	primary_email: '',
	gender: '',
	user_type: 'guest',
};

export default {
	props: { 
		show: { type: Boolean, default: false },
	},

	data()
	{
		return {
			guest: { ...defaultGuest },

			rules: {
				first_name: [ v => !!v || 'Please enter the guest\'s first name' ],
				last_name: [ v => !!v || 'Please enter the guest\'s last name' ],
				gender: [ v => !!v || 'Please select the guest\'s gender' ],
				primary_email: [ 
					v => !!v || 'Please enter the guest\'s email',
					v => validate.email(v) || 'Invalid email address',
				]
			},

			genderOptions: [
				{ text: '(select gender)', value: '' },
				{ text: 'Male', value: 'male' },
				{ text: 'Female', value: 'female' },
				{ text: 'Non-specified/Other', value: 'other' },
			],

			classes: [],

			saving: false,
			doesExpire: false,
		}
	},


	methods: {
		...mapMutations('teesheet', ['addTeetimePlayer']),
		...mapActions('teesheet', ['addPlayerToReservation']),

		resetForm()
		{
			this.guest = { ...defaultGuest };
		},

		async save()
		{
			// validate input
			if (!this.$refs.form.validate())
				return;

			// add guest to database and to reservation
			this.saving = true;
			try
			{
				// send new guest data to server
				var result = await this.$api.post('/users', { user: this.guest });

				// add guest to reservation
				this.addPlayerToReservation({ ...this.guest, id: result.data.user.id });
				this.dismiss();
			}
			finally
			{
				this.saving = false;
			}
		},

		dismiss()
		{
			this.resetForm();
			this.$emit('update:show', false);
		}
	}
}
</script>