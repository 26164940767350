
import router from 'igolf/users/router';

import { addRequireUserGuard } from '../shared/require-user';

import GuestListMain from './comp/GuestListMain.vue';

router.addRoutes(addRequireUserGuard([
	{
		name: 'users-guest-list',
		path: '/guests',
		component: GuestListMain,
	}
]));