<template>
	<div>
		<table class="teetimes-table" cellpadding="0" cellspacing="0" border="0">
			<thead>
				<tr>
					<th class="time-cell">Time</th>
					<template v-if="isMobile">
						<th>Players</th>
					</template>

					<template v-else>
						<th v-for="n in teetimes.perRow" :key="n">Player #{{ n }}</th>
					</template>
				</tr>
			</thead>

			<!-- DISPLAY EACH SEGMENT OF TEESHEET -->
			<tbody 
				v-for="(seg, index) in segments" 
				:key="index"
				class="teesheet-section"
				:class="{ 'is-block-section': seg.block != null }"
				:style="{ 'border-color': seg.block ? seg.block.teesheet_bg_color : '' }">

				<tr v-if="seg.block != null && hideLotteryHeaders(seg.block) && showDescriptionOnly(seg.block)" :key="`block-header-${seg.block.id}`">
					<td class="teetime-block-row" :colspan="columns" :style="{ borderLeftColor: 'rgb(244 64 64)', backgroundColor : 'white' }" title="This is a hidden block">
						<p class="ck-content" v-html="getBlockDescriptionForHiddenBlock(seg)"></p>
					</td>
				</tr>
				<!-- DISPLAY BLOCK START INTERFACE -->
				<tr v-if="seg.block != null && !hideLotteryHeaders(seg.block)" :key="`block-header-${seg.block.id}`">
					<td class="teetime-block-row" :colspan="columns" :style="{ borderLeftColor: seg.block.teesheet_bg_color }">
						<div v-if="seg.block.start_type == 'blocked'">
							<strong>No bookings allowed:</strong>
							{{ seg.blockRange.start_time | to12Hour }} - 
							{{ seg.blockRange.end_time | to12Hour }}
						</div>

						<div v-if="seg.block.is_lottery">
							<ul class="block-range-list">
								<li><strong>Lottery block:</strong></li>
								<li v-for="bs in getBlockSegments(seg.block.id)" :key="`${bs.block.id}-${bs.blockRange.start_time}`" :class="{ 'is-current-segment': checkSegmentRanges(seg.blockRange, bs.blockRange) }">
									<span>{{ bs.blockRange.start_time | to12Hour }} - {{ bs.blockRange.end_time | to12Hour }}</span>
								</li>
							</ul>

							<ul v-if="lotteryGroups[seg.block.id]" class="block-range-list">
								<li><strong>Lottery group:</strong></li>
								<li>{{ lotteryGroups[seg.block.id].map(u => u.name).join(", ") }}</li>
							</ul>

							<!--
							{{ seg.blockRange.start_time | to12Hour }} - 
							{{ seg.blockRange.end_time | to12Hour }}
							-->
						</div>

						<p class="ck-content" v-html="getBlockDescription(seg.block.description)"></p>

						<!-- LOTTERY BUTTONS -->
						<div v-if="isMember && seg.block.is_lottery && seg.block.lottery_status == 'open'">
							<template v-if="lotteriesEntered[seg.block.id]">
								<ui-button 
									:block="$breakpoints.smAndDown"
									:class="{ 'mb-4' : $breakpoints.smAndDown }"
									color="front-primary" 
									icon="fas fa-edit"
									:to="getLotteryEntryLink(seg.block.id)">
									Modify Entry
								</ui-button>

								<ui-button 
									:block="$breakpoints.smAndDown"
									:class="{ 'mb-4' : $breakpoints.smAndDown }"
									color="front-primary" 
									icon="fas fa-times-circle"
									@click="cancelLotteryEntry(seg.block.id)">
									Cancel Entry
								</ui-button>
							</template>

							<template v-else-if="lotteryGroups[seg.block.id]">
								<ui-button 
									:block="$breakpoints.smAndDown"
									:class="{ 'mb-4' : $breakpoints.smAndDown }"
									color="front-primary" 
									icon="fas fa-times"
									@click="removeFromLotteryEntry(seg.block.id)">
									Remove Myself
								</ui-button>
							</template>

						
							<ui-button 
								v-else-if="userClass.online_booking_allowed"
								:block="$breakpoints.smAndDown"
								:class="{ 'mb-4' : $breakpoints.smAndDown }"
								color="front-primary" 
								icon="fas fa-plus"
								:to="getLotteryEntryLink(seg.block.id)">
								Create Entry
							</ui-button>

							<ui-button 
								v-if="courseParams.show_lottery_entries" 
								:block="$breakpoints.smAndDown"
								:class="{ 'mb-4' : $breakpoints.smAndDown }"
								color="front-primary" 
								icon="fas fa-list" 
								@click="viewLotteryEntries(seg.block.id)">
								View Entries
							</ui-button>
						</div>
					</td>
				</tr>

				<template v-if="canShowTeetimes(seg)">

					<!-- DISPLAY TEETIME ROWS -->
					<template v-for="tt in rowsInSegment(index)">
						<!-- display notes if any -->
						<tr v-for="note in tt.notes" :key="note.id">
							<td :colspan="columns" class="teetime-block-row" :style="{ background: '#efefef', borderLeftColor: seg.block ? seg.block.teesheet_bg_color : '' }">
								<div v-html="note.note_content"/>
							</td>
						</tr>

						<!-- display actual teetime row(s) -->
						<template v-if="tt.players.length > teetimes.perRow">
							<template v-for="(cr, index) in chunkedRows(tt.players)">
								<teetime-row :key="`${tt.id}_${index}`" :row="tt" :players="cr" :stack-players="isMobile"/>
							</template>
						</template>
						
						<teetime-row v-else :row="tt" :key="tt.id" :stack-players="isMobile"/>
					</template>
				
				</template>

				<!-- DISPLAY BLOCK END INTERFACE -->
				<tr v-if="seg.block != null && seg.block.start_type != 'blocked' && !hideLotteryHeaders(seg.block)" :key="`block-footer-${seg.block.id}`">
					<td class="teetime-block-row" :colspan="columns" :style="{ borderLeftColor: seg.block.teesheet_bg_color }">
						End of block
					</td>
				</tr>

			</tbody>


		</table>

		<lottery-entries-popup />
	</div>
</template>

<script>
import _ from 'lodash/array';
import { mapState, mapGetters, mapActions } from 'vuex';

import filters from 'igolf/shared/filters';
import alerts from 'igolf/shared/alerts';

import TeetimeRow from './TeetimeRow.vue';
import LotteryEntriesPopup from './LotteryEntriesPopup.vue';

const { to12Hour } = filters.filters;

export default {
	components: { TeetimeRow, LotteryEntriesPopup },
	mixins: [filters],
	inject: ['$setLoadingCover'],

	data()
	{
		return {
			loading: false,
			inBlock: false,
		}
	},

	computed: {
		...mapGetters('teesheet', ['rowsInSegment']),
		...mapState({
			courseParams: state => state.teesheet.courseParams,
			teetimes: state => state.teesheet.teetimes,
			segments: state => state.teesheet.segments,
			course: state => state.teesheet.course,
			date: state => state.teesheet.date,
			lotteriesEntered: state => state.teesheet.userLotteriesEntered,
			lotteryGroups: state => state.teesheet.userLotteryGroups,
			currentUser: state => state.shell.currentUser,
			userClass: state => state.shell.userClass,
		}),

		isMobile()
		{
			return this.$breakpoints.smAndDown;
		},

		columns()
		{
			return this.isMobile ? 2 : this.teetimes.perRow+1;
		},

		isMember()
		{
			return this.currentUser != null && !this.currentUser.is_public;
		},

	},

	methods: {
		getBlockDescription(description)
		{	
          return description.replace(/<p>(.*?)<\/p>/, '$1');		
		},
		getBlockDescriptionForHiddenBlock(segment)
		{	
          return "Block:" + to12Hour(segment.blockRange.start_time) +" To "+ to12Hour(segment.blockRange.end_time) + "  <br>" + segment.block.description.replace(/<p>(.*?)<\/p>/, '$1');		
		},
		hideLotteryHeaders(block)
		{
			return block && (block.is_lottery && this.courseParams.hide_lotteries_from_public) || (block.hide_from_users);
		},

		showDescriptionOnly(block)
		{
			return block && block.hide_from_users && !(block.is_lottery && this.courseParams.hide_lotteries_from_public);
		},

		getBlockSegments(id)
		{
			return this.segments.filter(s => s.block && s.block.id == id);
		},

		checkSegmentRanges(a, b)
		{
			return a.start_time == b.start_time && a.end_time == b.end_time;
		},

		getLotteryEntryLink(blockId)
		{
			var entryId = this.lotteriesEntered[blockId] || 'new';
			return `/teesheet/${this.course.url_slug}/${this.date}/lottery_entry/${blockId}/${entryId}`;
		},

		async cancelLotteryEntry(blockId)
		{
			var entryId = this.lotteriesEntered[blockId];
			if (!entryId)
				return;

			if (!await alerts.confirm('Are you sure you want to cancel your lottery entry?', 'Cancel Lottery Entry', 'question'))
				return;

			this.$setLoadingCover(true);

			try
			{
				await this.$api.delete(`/lottery_entries/${blockId}/${entryId}`);
				location.reload();
			}
			finally
			{
				this.$setLoadingCover(false);
			}
		},

		async removeFromLotteryEntry(blockId)
		{
			if (!await alerts.confirm('Are you sure you want to remove yourself from this lottery group?', 'Remove From Group', 'question'))
				return;

			this.$setLoadingCover(true);

			try
			{
				await this.$api.delete('/lottery_entries/remove_self', { params: { block_id: blockId } });
				location.reload();
			}
			finally
			{
				this.$setLoadingCover(false);
			}
		},

		viewLotteryEntries(blockId)
		{
			this.$store.commit('teesheet/showLotteryEntries', blockId);
		},

		canShowTeetimes(segment)
		{
			// if this is not a block segment then show times
			if (!segment.block)
				return true;

			// if this is a blocked off segment return false
			if (segment.block.start_type == 'blocked')
				return false;

			// if this is a lottery block and it has not run and accepted then return false
			if (segment.block.is_lottery && segment.block.lottery_status != 'accepted')
				return false;

			return true;
		},

		chunkedRows(players)
		{
			return _.chunk(players, this.teetimes.perRow);
		}
	}
}
</script>